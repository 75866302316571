import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import { Typography } from '@material-ui/core'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  typography: {
    marginLeft: '12px',
    height: '18px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#72809d',
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

function PackageForm(props) {
  const classes = useStyles()
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()

  const { isLoading, packageData, setEnableForm } = props

  const [values, setValues] = useState({
    name: '',
    name_err: '',
    body: '',
    body_err: ''
  })

  const checkErr = objects => {
    if (objects.name_err || objects.body_err) {
      setEnableForm(false)
    } else {
      setEnableForm(true)
    }
  }

  const handleInputRequiredChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const newValues = { ...values, [name]: value }
    if (value) {
      newValues[name + '_err'] = ''
    } else {
      newValues[name + '_err'] = '入力してください'
    }
    setValues(newValues)
    checkErr(newValues)
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    const initializeValues = {
      name:
        'package' in packageData && packageData.package
          ? packageData.package.name
          : '',
      name_err:
        'package' in packageData && packageData.package
          ? packageData.package.name
            ? ''
            : '入力してください'
          : '',
      body:
        'package' in packageData && packageData.package
          ? packageData.package.body
          : '',
      body_err:
        'package' in packageData && packageData.package
          ? packageData.package.body
            ? ''
            : '入力してください'
          : ''
    }
    setValues(initializeValues)
    checkErr(initializeValues)
  }, [packageData, setValues])

  return (
    <FormLayout title="サービス内容">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              error={values.name_err != '' ? true : false}
              helperText={values.name_err}
              required
              id="name"
              name="name"
              label="サービス名"
              fullWidth
              variant="filled"
              defaultValue={values.name}
              onChange={handleInputRequiredChange}
              inputProps={{ maxLength: 100 }}
              disabled={!isUnrestrictedRole}
            />
            <Typography className={classes.typography}>100文字以内</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              error={values.body_err != '' ? true : false}
              helperText={values.body_err}
              required
              id="body"
              name="body"
              label="説明文を記載"
              fullWidth
              multiline
              rows="8"
              variant="filled"
              defaultValue={values.body}
              onChange={handleInputRequiredChange}
              inputProps={{ maxLength: 2000 }}
              disabled={!isUnrestrictedRole}
            />
            <Typography className={classes.typography}>
              2,000文字以内
            </Typography>
          </Grid>
        </Grid>
      )}
    </FormLayout>
  )
}

export default PackageForm
