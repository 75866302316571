import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import Grid from '@material-ui/core/Grid'

import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import Loading from 'components/common/Loading'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  body: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W6, Hiragino Sans W3',
    fontWeight: '400',
    lineHeight: '1.4em',
    border: 'none',
    backgroundColor: '#f7f8fa'
  },
  helperText: {
    marginLeft: '14px'
  },
  disabledTextField: {
    backgroundColor: '#e0e0e0',
    color: '#9e9e9e'
  }
}))

function FaqForm(props) {
  const classes = useStyles()
  const { isLoading, faq } = props
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const [values, setValues] = useState({
    question: '',
    answer: ''
  })

  function handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    setValues({ ...values, [name]: value })
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    setValues({
      question: 'faq' in faq && faq.faq ? faq.faq.question : '',
      answer: 'faq' in faq && faq.faq ? faq.faq.answer : ''
    })
  }, [faq, setValues])

  return (
    <FormLayout title="FAQ内容">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                required
                id="question"
                name="question"
                label="質問"
                fullWidth
                variant="filled"
                helperText="100文字以内"
                defaultValue={values.question}
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
                disabled={isRestrictedRole}
              />
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                required
                label={'回答'}
                id="answer"
                name="answer"
                fullWidth
                rowsMin="6"
                className={`${classes.body} ${isRestrictedRole &&
                  classes.disabledTextField}`}
                defaultValue={values.answer}
                value={values.answer}
                onChange={handleInputChange}
                placeholder={'回答*'}
                maxLength={2000}
                disabled={isRestrictedRole}
              />
              <FormHelperText className={classes.helperText}>
                {'2,000文字以内'}
              </FormHelperText>
            </Grid>
          </Grid>
        </>
      )}
    </FormLayout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(null, mapDispatchToProps)(FaqForm)
