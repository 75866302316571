import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { handleClear, setLoadData } from 'actions/Actions'
import Main from './Main'
import Right from './Right'
import Footer from './Footer'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'

function Edit(props) {
  const { faqId } = useParams()
  const history = useHistory()
  const [faq, setFaq] = useState({})
  const [faqAttachment, setFaqAttachment] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    props.handleClear()
    if (!faqId) return
    fetchData(faqId)
  }, [props.handleClear])

  const fetchData = async faqId => {
    setIsLoading(true)
    await Promise.all([getFaq(faqId), getFaqAttachment(faqId)])
    setIsLoading(false)
  }

  const getFaq = async faqId => {
    const result = await QueryHelper('getFaq', { faqId })
    if (!result.error) {
      setFaq(result)
    } else {
      props.enqueueSnackbar(`FAQデータの詳細を取得できませんでした。`)
    }
  }

  const getFaqAttachment = async faqId => {
    const result = await QueryHelper('getFaqAttachment2', { faqId })
    setFaqAttachment(result)
  }

  const handleCancel = async event => {
    event.preventDefault()
    props.handleClear()
    if (!faqId) {
      setFaq({})
      setFaqAttachment({})
    } else {
      fetchData(faqId)
    }
  }

  const handleDelete = async event => {
    event.preventDefault()
    if (!window.confirm(`Faqを削除します。よろしいですか？`)) {
      return
    }
    props.handleClear()
    await deleteFaq()
    history.push(`/management/faq/list/1`)
  }

  const deleteFaq = async () => {
    props.setLoadData(true)
    const result = await MutationHelper('updateFaqAndAttachments', {
      input: { id: faqId, status: 3 }
    })
    if (result.error) {
      props.enqueueSnackbar('FAQを削除できませんでした。', {
        variant: 'error'
      })
      props.setLoadData(false)
    } else {
      props.handleClear()
      props.enqueueSnackbar('FAQを削除しました。', {
        variant: 'success'
      })
      props.setLoadData(false)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (Object.keys(props.parameters).length > 0) {
      putFaq()
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  const fileupload = async (creds, folder, file) => {
    console.log(JSON.stringify(file))
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  const putFaq = async () => {
    props.setLoadData(true)
    const { images, pdfs, videos, ...faq } = props.parameters
    let attachments = []
    if (images) {
      const creds = await Auth.currentCredentials()
      for (let file of images) {
        if ('id' in file) {
          attachments.push(file)
          continue
        }
        const result = await fileupload(creds, 'images', file)
        attachments.push({
          attachment_type_id: 1,
          mime_type: file.type,
          filename: file.name,
          key: result.key
        })
      }
    }
    if (pdfs) {
      const creds = await Auth.currentCredentials()
      for (let file of pdfs) {
        if ('id' in file) {
          attachments.push(file)
          continue
        }
        const result = await fileupload(creds, 'pdfs', file)
        attachments.push({
          attachment_type_id: 3,
          mime_type: file.type,
          filename: file.name,
          key: result.key
        })
      }
    }
    if (videos) {
      const creds = await Auth.currentCredentials()
      for (let file of videos) {
        if ('id' in file) {
          attachments.push(file)
          continue
        }
        const result = await fileupload(creds, 'videos', file)
        attachments.push({
          attachment_type_id: 2,
          mime_type: file.type,
          filename: file.name,
          key: result.key
        })
      }
    }

    if (faqId) {
      const result = await MutationHelper('updateFaqAndAttachments', {
        input: { id: faqId, ...faq, attachments }
      })
      if (result.error) {
        props.enqueueSnackbar('FAQを更新できませんでした。', {
          variant: 'error'
        })
        props.setLoadData(false)
      } else {
        props.handleClear()
        props.enqueueSnackbar('FAQを更新しました。', {
          variant: 'success'
        })
        fetchData(faqId)
        props.setLoadData(false)
      }
    } else {
      const result = await MutationHelper('createFaqAndAttachments', {
        input: { ...faq, attachments }
      })
      if (result.error) {
        props.enqueueSnackbar('FAQを登録できませんでした。', {
          variant: 'error'
        })
        props.setLoadData(false)
      } else {
        props.handleClear()
        props.enqueueSnackbar('FAQを登録しました。', {
          variant: 'success'
        })
        props.setLoadData(false)
        history.push(`/management/faq/edit/${result.faq.id}`)
      }
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ flex: 1, display: 'flex' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                flex: 1,
                margin: 32
              }}
            >
              <Main
                isLoading={isLoading}
                faq={faq}
                faqAttachment={faqAttachment}
              />
            </div>
            <div style={{ backgroundColor: '#fff' }}>
              <Footer handleCancel={handleCancel} handleDelete={handleDelete} />
            </div>
          </div>
          <div style={{ width: '364px' }}>
            <Right isLoading={isLoading} faq={faq} />
          </div>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Edit))
