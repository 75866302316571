import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { QueryHelper } from 'utils/api.utils'
import { makeStyles } from '@material-ui/core/styles'
import BulletinBoardForm from './BulletinBoardForm'
import Right from './Right'
import { BulletinBoardStatus } from 'utils/enum'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function Edit(props) {
  const classes = useStyles()
  const { id } = useParams()
  const [bulletinBoardTypeList, setBulletinBoardTypeList] = useState(null)
  const [bulletinBoard, setBulletinBoard] = useState(null)
  const [buildings, setBuildings] = useState(null)
  const [attachments, setAttachments] = useState(null)
  const [updatedUser, setUpdatedUser] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [bulletinBoardStatus, setBulletinBoardStatus] = useState(
    BulletinBoardStatus.draft.code
  )

  useEffect(() => {
    fetchData(id)
  }, [setBulletinBoard, setAttachments])

  const fetchData = async bulletinBoardId => {
    setIsLoading(true)
    await Promise.all([
      bulletinBoardId ? fetchBulletinBoard(bulletinBoardId) : '',
      fetchBulletinBoardTypeList()
    ])
    setIsLoading(false)
  }

  const fetchBulletinBoard = async bulletinBoardId => {
    const result = await QueryHelper('getBulletinBoard', {
      filter: { id: { eq: bulletinBoardId } }
    })
    if (!result.error) {
      setBulletinBoard(result.bulletin_board)
      setBuildings(result.building)
      setAttachments(result.attachment)
      setUpdatedUser(result.updated_user)
      setBulletinBoardStatus(result.status)
    } else {
      props.enqueueSnackbar(`お知らせデータの詳細を取得できませんでした。`)
    }
  }

  const fetchBulletinBoardTypeList = async () => {
    const result = await QueryHelper(
      'getBulletinBoardTypeList',
      { filter: {} },
      0,
      undefined
    )
    if (!result.error) {
      setBulletinBoardTypeList(result.data)
    } else {
      props.enqueueSnackbar(`お知らせ種別の詳細を取得できませんでした。`)
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.left}>
          <div className={classes.leftBody}>
            <BulletinBoardForm
              isLoading={isLoading}
              fetchBulletinBoard={fetchBulletinBoard}
              bulletinBoardTypeList={bulletinBoardTypeList}
              bulletinBoard={bulletinBoard}
              buildings={buildings}
              attachments={attachments}
              bulletinBoardStatus={bulletinBoardStatus}
            />
          </div>
          <div className={classes.leftBottom}></div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightBody}>
            <Right
              isLoading={isLoading}
              bulletinBoard={bulletinBoard}
              updatedUser={updatedUser}
              fetchBulletinBoard={fetchBulletinBoard}
              setBulletinBoardStatus={setBulletinBoardStatus}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default withSnackbar(Edit)
