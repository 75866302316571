import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

function BookmarkForm(props) {
  const { role124 } = useRole()
  const isUnrestrictedRole = role124()
  const { isLoading, bookmark } = props
  const [values, setValues] = useState({
    name: ''
  })

  function handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    setValues({ ...values, [name]: value })
    props.handleChange({ [name]: value })
  }

  useEffect(() => {
    setValues({
      name: 0 !== Object.keys(bookmark).length ? bookmark.bookmark.name : ''
    })
  }, [bookmark, setValues])

  return (
    <FormLayout title="しおり情報">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              required
              id="name"
              name="name"
              label="しおり名"
              fullWidth
              autoComplete="name"
              variant="filled"
              defaultValue={values.name}
              onChange={handleInputChange}
              disabled={!isUnrestrictedRole}
            />
          </Grid>
        </Grid>
      )}
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkForm)
