import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImageForm from './ImageForm'
import CompanyForm from './CompanyForm'
import InquireForm from './InquireForm'
import PrivacyPolicyForm from './PrivacyPolicyForm'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1,
    backgroundColor: '#fff'
  },
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  bottom: {
    flex: 1,
    backgroundColor: '#fff'
  },
  divider: {
    width: '100%',
    height: '24px'
  }
}))

function Left(props) {
  const classes = useStyles()

  const {
    isLoading,
    company,
    setEnableCompanyForm,
    setEnableInquireForm,
    setEnablePrivacyPolicyForm
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <ImageForm isLoading={isLoading} company={company} />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <CompanyForm
          isLoading={isLoading}
          company={company}
          setEnableForm={setEnableCompanyForm}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.bottom}>
        <InquireForm
          isLoading={isLoading}
          company={company}
          setEnableForm={setEnableInquireForm}
        />
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <PrivacyPolicyForm
          isLoading={isLoading}
          company={company}
          setEnableForm={setEnablePrivacyPolicyForm}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Left)
