import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import CreateFormDialog from './CreateFormDialog'
import FormControl from '@material-ui/core/FormControl'
import Dropdown from 'components/common/Form/Dropdown'
import { BULK_DELIVERY_STATUS_LIST } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import Image from './Image'
import Pdf from './Pdf'
import uuid from 'uuid/v4'
import { Auth, Storage } from 'aws-amplify'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useHistory } from 'react-router-dom'
import { MutationHelper } from 'utils/api.utils'
import {
  setSelectContractList,
  setCsvInformation,
  setMatchCsvValue,
  setTargetIdList,
  setCsvHeader,
  setFilterCsvInformation,
  setMatchContractIdList,
  setBulkDeliveryDetailList,
  setImageList,
  setPdfList
} from 'actions/Actions'
import { ChatAnswerList, BulkDeliverySentStatus } from 'utils/enum'
import { utilsCsv } from 'utils/csv.utils'
import LoadingWithBackground from 'components/common/LoadingWithBackground'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  },
  body: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '16px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W3',
    lineHeight: '24px',
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    backgroundColor: '#fff'
  },
  labelButtonUpper: {
    width: '100%',
    padding: '15px 20px',
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  labelButtonSecond: {
    width: '100%',
    padding: '15px 20px',
    borderBottom: 'solid 1px rgba(158, 158, 158, 0.5)',
    borderLeft: 'solid 1px rgba(158, 158, 158, 0.5)',
    borderRight: 'solid 1px rgba(158, 158, 158, 0.5)'
  },
  footerButton: {
    width: '100%',
    padding: '15px 20px',
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  section: {
    padding: '15px 20px'
  },
  subTitle: {
    marginTop: '24px'
  },
  subTitlePositionChange: {
    position: 'relative',
    top: '60px'
  },
  chatStatus: {
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    marginTop: '0px',
    padding: '15px 20px'
  },
  flexButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center'
  },
  item: {
    padding: '5px 8px 20px'
  },
  itemRow: {
    padding: '5px 8px 5px'
  },
  nextButton: {
    color: theme.palette.c_info.lighter,
    backgroundColor: theme.palette.c_secondary.main,
    borderColor: theme.palette.c_secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue2.main
    },
    padding: '6px 36px'
  },
  backButton: {
    color: theme.palette.c_black.dark,
    backgroundColor: theme.palette.c_gray.light,
    borderColor: theme.palette.c_gray.light,
    '&:hover': {
      color: theme.palette.c_black.contrastText,
      backgroundColor: theme.palette.c_gray.main
    },
    padding: '6px 60px',
    marginRight: '30px'
  },
  deleteButton: {
    color: theme.palette.c_thirdly.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    borderColor: theme.palette.c_purple.main,
    '&:hover': {
      color: theme.palette.c_thirdly.contrastText,
      backgroundColor: theme.palette.c_purple.dark,
      borderColor: theme.palette.c_purple.dark
    },
    padding: '6px 60px'
  },
  messageSelect: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sentenceItem: {
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    padding: '15px 20px'
  },
  itemList: {
    display: 'flex',
    justifyContent: 'flex-start',
    listStyle: 'none',
    padding: '0px',
    flexWrap: 'wrap'
  },
  itemLi: {
    color: '#fff',
    padding: '5px 25px',
    marginRight: '10px',
    border: 'solid 1px rgba(158, 158, 158)',
    borderRadius: '25px',
    backgroundColor: 'rgba(158, 158, 158)',
    marginBottom: '10px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },
  templateRegister: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '58px'
  },
  templateRegisterButton: {
    backgroundColor: '#1bae9f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1bae9f'
    }
  },
  transitionButtonSet: {
    width: '100%',
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rowButtonArea: {
    marginTop: '41px'
  },
  primaryButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    },
    padding: '6px 60px'
  },
  secondaryButton: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    },
    padding: '6px 36px',
    marginRight: '30px'
  },
  attachmentBody: {
    marginTop: 24
  },
  input: {
    display: 'none'
  },
  attachments: {
    border: 'solid 1px rgba(158, 158, 158, 0.5)'
  },
  treadmemoContentLimitStyle: {
    fontFamily: 'Hiragino Sans W4',
    fontSize: '13px'
  },
  treadmemoContentLimit: {
    textAlign: 'right'
  },
  treadmemoContentLimitTypeError: {
    textAlign: 'right',
    position: 'relative',
    top: '-20px'
  },
  selectTemplate: {
    marginLeft: 'auto'
  },
  diplayNone: {
    display: 'none'
  }
}))

function EditContent(props) {
  const classes = useStyles()
  const [selectedParentCategory, setSelectedParentCategory] = useState(null)
  const [selectedChildCategory, setSelectedChildCategory] = useState(null)
  const [selectChildCategoryList, setSelectChildCategoryList] = useState(null)
  const [parentCategoryList, setParentCategoryList] = useState(null)
  const [childCategoryList, setChildCategoryList] = useState(null)
  const [companyGroupList, setCompanyGroupList] = useState(null)
  const [companyUserList, setCompanyUserList] = useState(null)
  const [companyUserMap, setCompanyUserMap] = useState(null)
  const [
    selectedAssignedCompanyGroup,
    setSelectedAssignedCompanyGroup
  ] = useState(null)
  const [
    selectedAssignedCompanyUser,
    setSelectedAssignedCompanyUser
  ] = useState(null)
  const [assignedCompanyUserList, setAssignedCompanyUserList] = useState(null)
  const [selectedSendCompanyUser, setSelectedSendCompanyUser] = useState(null)
  const [selectedConfirmation, setSelectedConfirmation] = useState(null)
  const [templateList, setTemplateList] = useState(null)
  const [template, setTemplate] = useState(null)
  const [templateBody, setTemplateBody] = useState('')
  const [validateTemplateBody, setValidateTemplateBody] = useState(null)
  const [images, setImages] = useState([])
  const [pdf, setPdf] = useState(null)
  const [templateTitle, setTemplateTitle] = useState()
  const [
    validateSelectedParentCategory,
    setValidateSelectedParentCategory
  ] = useState(null)
  const [
    validateSelectedChildCategory,
    setValidateSelectedChildCategory
  ] = useState(null)
  const [selectedChatAnswer, setSelectedChatAnswer] = useState(
    ChatAnswerList.nothing.value
  )
  const [selectedSendState, setSelectedSendState] = useState(null)
  const [validateSelectedSendState, setValidateSelectedSendState] = useState(
    null
  )
  const [
    validateSelectedSendCompanyUser,
    setValidateSelectedSendCompanyUser
  ] = useState(null)
  const [validateSelectedChatAnswer, setValidateSelectedChatAnswer] = useState(
    null
  )
  const [validateTreadMemo, setValidateTreadMemo] = useState(null)
  const [param, setParam] = useState(null)
  const history = useHistory()
  const [deliveryName, setDeliveryName] = useState(null)
  const params = {}
  const [threadMemo, setThreadMemo] = useState(null)
  const [validateDeliveryName, setValidateDeliveryName] = useState(null)
  const [csvInformationList, setCsvInformationList] = useState(null)
  const [matchCsvValue, setMatchCsvValue] = useState(null)
  const [selectContractList, setSelectContractList] = useState(null)
  const csvHeader = props.csvHeader ? props.csvHeader.csvHeader : []
  const [targetIdList, setTargetIdList] = useState(null)
  const [loading, setLoading] = useState(false)

  function validateFormCommon() {
    let isError = false

    if (threadMemo) {
      if (threadMemo.length > 300) {
        setValidateTreadMemo({
          hasError: true,
          message: 'スレッドメモは300文字以内で入力してください'
        })
        isError = true
      } else {
        setValidateTreadMemo(null)
      }
    }
    if (!selectedParentCategory) {
      setValidateSelectedParentCategory({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedParentCategory({})
    }
    if (!selectedChildCategory) {
      setValidateSelectedChildCategory({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedChildCategory({})
    }
    if (!selectedChatAnswer) {
      setValidateSelectedChatAnswer({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedChatAnswer({})
    }
    if (!selectedSendState) {
      setValidateSelectedSendState({ hasError: true, message: '必須です' })
      isError = true
    } else if (
      selectedChatAnswer != ChatAnswerList.nothing.value &&
      selectedSendState == BulkDeliverySentStatus.complete.value
    ) {
      // 回答ボタンありの場合は、ステータス完了を選択できない
      setValidateSelectedSendState({
        hasError: true,
        message: 'チャット回答ボタンを送る場合、完了は選択できません'
      })
      isError = true
    } else {
      setValidateSelectedSendState({})
    }
    if (!templateBody || templateBody === '') {
      setValidateTemplateBody({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateTemplateBody({})
    }

    return isError
  }

  function validateFormMessage() {
    let isError = false

    if (!deliveryName) {
      setValidateDeliveryName({ hasError: true, message: '必須です' })
      isError = true
    } else if (deliveryName.length > 30) {
      setValidateDeliveryName({
        hasError: true,
        message: '30文字以内で入力してください'
      })
      isError = true
    } else {
      setValidateDeliveryName({})
    }

    if (!selectedSendCompanyUser) {
      setValidateSelectedSendCompanyUser({
        hasError: true,
        message: '必須です'
      })
      isError = true
    } else {
      setValidateSelectedSendCompanyUser({})
    }

    return isError
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  const fetchCategoryList = async () => {
    const result = await QueryHelper('getLargeCategoryAndMediumCategory')
    if (!result.error) {
      const parents = [{ value: 0, label: '' }]
      const children = { 0: [] }
      for (let row1 of result) {
        parents.push({
          value: row1.large_categories.id,
          label: row1.large_categories.name
        })
        const elements = [{ value: 0, label: '' }]
        for (let row2 of row1.medium_categories) {
          elements.push({
            value: row2.id,
            label: row2.name
          })
        }
        children[row1.large_categories.id] = elements
      }
      setParentCategoryList(parents)
      setChildCategoryList(children)
    } else {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      eq: 1
    }
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          const companyGroupItem = {
            value: item.id,
            label: item.name
          }
          companyGroupItems.push(companyGroupItem)
        }
      }
      setCompanyGroupList(companyGroupItems)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      eq: 1
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          const companyUserItem = {
            value: item.company_user.id,
            label: item.company_user.display_name
              ? item.company_user.display_name
              : item.company_user.name
          }
          companyUserItems.push(companyUserItem)
          if (!userMap.has(item.company_group.id)) {
            userMap.set(item.company_group.id, [companyUserItem])
          } else {
            const companyUserItemArray = userMap.get(item.company_group.id)
            companyUserItemArray.push(companyUserItem)
          }
        }
      }
      setCompanyUserList(companyUserItems)
      setCompanyUserMap(userMap)
    }
  }

  const fetchTemplateList = async (offset, limit) => {
    const result = await QueryHelper(
      'getBulkDeliveryTemplates',
      {
        offset: offset,
        limit: 0
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('テンプレートを取得できませんでした', {
        variant: 'error'
      })
    } else {
      const templateList = [{ value: 0, label: '未選択' }]
      for (let item of result.data) {
        const templateLists = {
          label: item.title,
          value: item.id,
          body: item.body,
          item: item
        }
        templateList.push(templateLists)
      }
      setTemplateList(templateList)
    }
  }

  const handleChangeParentCategory = (event, value) => {
    if (value == 0) {
      setSelectChildCategoryList([])
    } else {
      setSelectChildCategoryList(childCategoryList[event.target.value])
    }
    setSelectedParentCategory(event.target.value)
    setSelectedChildCategory(0)
  }

  const handleChangeChildCategory = event => {
    setSelectedChildCategory(event.target.value)
  }

  const handleChangeAssignedCompanyGroup = (event, value) => {
    setSelectedAssignedCompanyGroup(event.target.value)
    setAssignedCompanyUserList(companyUserMap.get(event.target.value))
    setSelectedAssignedCompanyUser(0)
  }

  const handleChangeAssignedCompanyUser = (event, value) => {
    if (event.target.value != '') {
      setSelectedAssignedCompanyUser(event.target.value)
    }
  }

  const handleSelectedSendCompanyUser = (event, value) => {
    setSelectedSendCompanyUser(event.target.value)
  }

  const handleChangeSendState = (event, value) => {
    setSelectedSendState(event.target.value)
  }

  const handleChangeSelectedChatAnswer = (event, value) => {
    setSelectedChatAnswer(event.target.value)
  }

  const handleChangeTemplate = event => {
    const template = templateList.find(
      element => element.value == event.target.value
    )
    const textareapre = document.getElementById('template').value
    if (!textareapre) {
      changeTemplateBody(event)
      changeCategory(template.item)
      changeAnswerButtonType(template.item)
      changeSendStatus(template.item)
      changeAttachments(template.item)
      return
    }
    if (!window.confirm('本文の内容が破棄されますが、よろしいですか？')) {
      event.target.value = template
      return
    }
    const value = JSON.stringify(event.target.value)
    const textarea = document.getElementById('template')
    if (value == 0) {
      textarea.value = ''
      changeCategory(template.item)
      changeAnswerButtonType(template.item)
      changeSendStatus(template.item)
      changeAttachments(template.item)
      return
    }
    changeTemplateBody(event)
    changeCategory(template.item)
    changeAnswerButtonType(template.item)
    changeSendStatus(template.item)
    changeAttachments(template.item)
  }

  const changeTemplateBody = event => {
    const value = JSON.stringify(event.target.value)
    const textarea = document.getElementById('template')
    let selectTemplateList = templateList.filter(
      template => template.value == value
    )
    setTemplate(selectTemplateList[0].value)
    setTemplateBody(selectTemplateList[0].body)
    textarea.value = selectTemplateList[0].body
  }

  const changeCategory = template => {
    if (!template) {
      setSelectChildCategoryList([])
      setSelectedParentCategory(0)
      setSelectedChildCategory(0)
      return
    }
    setSelectedParentCategory(template.large_category.id)
    setSelectChildCategoryList(childCategoryList[template.large_category.id])
    setSelectedChildCategory(template.medium_category.id)
  }

  const changeAnswerButtonType = template => {
    if (!template) {
      setSelectedChatAnswer(ChatAnswerList.nothing.value)
      return
    }
    setSelectedChatAnswer(
      ChatAnswerList.fromKey(template.answer_button_type).value
    )
  }

  const changeSendStatus = template => {
    if (!template) {
      setSelectedSendState(BulkDeliverySentStatus.notCompatible.value)
      return
    }
    setSelectedSendState(
      BulkDeliverySentStatus.fromKey(template.sent_status).value
    )
  }

  const changeAttachments = template => {
    if (!template) {
      return
    }
    const images = []
    let pdf = null
    if (template.attachments !== null) {
      for (let attachment of template.attachments) {
        if (attachment.attachment_type_id === 1) {
          images.push(attachment)
        }
        if (attachment.attachment_type_id === 3) {
          pdf = attachment
        }
      }
    }
    setImages(images)
    setPdf(pdf)
  }

  const handleChangeTemplateBody = event => {
    setTemplateBody(event.target.value)
  }

  const handleChangeImage = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setImages([...images, file])
    }
    reader.readAsDataURL(file)
  }

  const handleDeleteImage = index => event => {
    const splicedImages = [...images.filter((item, i) => i !== index)]
    document.getElementById('contained-button-image').value = null
    setImages([...splicedImages])
  }

  const handleChangePdf = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setPdf(file)
    }
    reader.readAsDataURL(file)
  }

  const handleDeletePdf = event => {
    document.getElementById('contained-button-pdf').value = null
    setPdf(null)
    setSelectedConfirmation(JSON.stringify(event.target.value))
  }

  const handleChangeThreadMemo = event => {
    setThreadMemo(event.target.value)
  }

  const handleChangeDeliveryName = event => {
    setDeliveryName(event.target.value)
  }

  const handleOpenTemplateTitle = async event => {
    event.preventDefault()

    let responseMessage = validateFormMessage()
    let responseCommon = validateFormCommon()

    if (responseMessage || responseCommon) {
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    params.body = templateBody
    params.medium_category_id = selectedChildCategory
    params.answer_button_type = ChatAnswerList.fromValue(selectedChatAnswer).key
    params.sent_status = BulkDeliverySentStatus.fromValue(selectedSendState).key

    if ((images && images.length > 0) || pdf) {
      const creds = await Auth.currentCredentials()
      params.attachments = []
      const attachmentImages = []
      for (let image of images) {
        if (!image.id) {
          const result = await fileupload(creds, 'images', image)
          attachmentImages.push({
            attachment_type_id: 1,
            mime_type: image.type,
            filename: image.name,
            key: result.key
          })
        } else {
          attachmentImages.push({
            attachment_type_id: 1,
            mime_type: image.type,
            filename: image.filename,
            key: image.key
          })
        }
      }
      if (attachmentImages.length > 0) {
        params.attachments = attachmentImages
      }

      if (pdf) {
        if (!pdf.id) {
          const result = await fileupload(creds, 'pdfs', pdf)
          params.attachments.push({
            attachment_type_id: 3,
            mime_type: pdf.type,
            filename: pdf.name,
            key: result.key
          })
        } else {
          params.attachments.push({
            attachment_type_id: 3,
            mime_type: pdf.type,
            filename: pdf.filename,
            key: pdf.key
          })
        }
      }
    }

    setParam(params)
    handleOpen()
  }

  const handleSubmit = async event => {
    setLoading(true)
    await handleCreateBulkDeliveryMessages(event)
    setLoading(false)
  }

  const handleCreateBulkDeliveryMessages = async event => {
    props.setImageList({
      imageList: images
    })
    props.setPdfList({
      pdfList: pdf
    })

    event.preventDefault()

    let responseMessage = validateFormMessage()
    let responseCommon = validateFormCommon()

    if (responseMessage || responseCommon) {
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    const resultConfirm = window.confirm(
      `配信内容を確定しますがよろしいですか？`
    )
    if (!resultConfirm) return

    const paramsMessage = {}
    // 配信名
    paramsMessage.name = deliveryName
    // ステータス
    paramsMessage.status = BULK_DELIVERY_STATUS_LIST[4].key
    // カテゴリ_ID
    paramsMessage.category_id = selectedChildCategory
    // 担当グループID
    if (selectedAssignedCompanyGroup) {
      paramsMessage.assigned_group_by = selectedAssignedCompanyGroup
    }
    // 担当者ID
    if (selectedAssignedCompanyUser) {
      paramsMessage.assigned_by = selectedAssignedCompanyUser
    }
    // 送信者ID
    paramsMessage.sender_id = selectedSendCompanyUser
    // 回答ボタン送信種別
    paramsMessage.answer_button_type = ChatAnswerList.fromValue(
      selectedChatAnswer
    ).key
    // 送信後ステータス
    paramsMessage.sent_status = BulkDeliverySentStatus.fromValue(
      selectedSendState
    ).key
    // 備考メモ
    if (threadMemo) {
      paramsMessage.thread_memo = threadMemo
    }

    // 添付ファイル
    if ((images && images.length > 0) || pdf) {
      paramsMessage.attachments = []
      const creds = await Auth.currentCredentials()
      for (let image of images) {
        if (!image.id) {
          const result = await fileupload(creds, 'images', image)
          paramsMessage.attachments.push({
            attachment_type_id: 1,
            mime_type: image.type,
            filename: image.name,
            key: result.key
          })
        } else {
          paramsMessage.attachments.push({
            attachment_type_id: 1,
            mime_type: image.type,
            filename: image.filename,
            key: image.key
          })
        }
      }

      if (pdf) {
        if (!pdf.id) {
          const result = await fileupload(creds, 'pdfs', pdf)
          paramsMessage.attachments.push({
            attachment_type_id: 3,
            mime_type: pdf.type,
            filename: pdf.name,
            key: result.key
          })
        } else {
          paramsMessage.attachments.push({
            attachment_type_id: 3,
            mime_type: pdf.type,
            filename: pdf.filename,
            key: pdf.key
          })
        }
      }
    }

    // 送信メッセージ
    paramsMessage.message = templateBody

    if (csvInformationList != null) {
      paramsMessage.csv = csvInformationList
      paramsMessage.type = 'CSV'
    }
    if (matchCsvValue != null) {
      paramsMessage.selected_csv_column_name = utilsCsv(matchCsvValue)
    }

    if (selectContractList != null) {
      paramsMessage.kyk_id_list = selectContractList
      paramsMessage.type = 'SEARCH'
    }

    if (targetIdList != null) {
      paramsMessage.kyk_id_list = targetIdList
    }

    const tmpParams = JSON.parse(JSON.stringify(paramsMessage))
    tmpParams.large_category_id = selectedParentCategory

    let result

    result = await MutationHelper('createBulkDeliveryMessages', {
      input: paramsMessage
    })
    if (result.error) {
      console.log(result)
      props.enqueueSnackbar('保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('保存しました', {
        variant: 'success'
      })

      props.setBulkDeliveryDetailList({
        bulkDeliveryDetailList: tmpParams
      })

      history.push({
        pathname: `/message/bulk-delivery/confirm/${result.id}`,
        state: { referrer: '/message/bulk-delivery/edit' }
      })
    }
  }

  const goToReturnPreviousScreen = () => {
    const result = window.confirm(`配信内容が削除されますがよろしいですか？`)
    if (!result) return
    if (selectContractList) {
      props.setSelectContractList({
        // データ検索画面ではhookで管理している値を入れているため
        selectContractList: null
      })
    }
    if (csvInformationList) {
      props.setFilterCsvInformation({
        filterCsvInformation: null
      })
    }
    if (targetIdList == null) {
      history.push(`/message/bulk-delivery/search/1`)
      return
    }
    if (selectContractList == null) {
      history.push(`/message/bulk-delivery/select/1`)
      return
    }
    // データ検索画面、CSV取り込み画面以外から遷移した場合
    history.push(`/message/bulk-delivery/select/1`)
  }

  useEffect(() => {
    fetchCategoryList()
    fetchCompanyGroupList()
    fetchCompanyUserList()
    fetchTemplateList()
    if (props.list != undefined || props.value != undefined) {
      if (
        props.filterCsvInformation != null ||
        props.value.matchCsvValue != null
      ) {
        setCsvInformationList(props.filterCsvInformation.filterCsvInformation)
        setMatchCsvValue(props.value.matchCsvValue)
      }
    }
    if (props.selectContractList != undefined) {
      if (props.selectContractList.selectContractList != null) {
        setSelectContractList(
          Array.from(props.selectContractList.selectContractList)
        )
      }
    }
    if (props.targetIdList != undefined) {
      if (props.targetIdList.targetIdList != null) {
        setTargetIdList(props.targetIdList.targetIdList)
      }
    }

    // 一度最終確認画面へ遷移してから配信内容設定画面へ戻ってきた時にセットする
    if (props.bulkDeliveryDetailList != undefined) {
      if (props.bulkDeliveryDetailList.bulkDeliveryDetailList != null) {
        setDeliveryName(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.name
        )
        setTemplateBody(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.message
        )
        setImages(props.imageList.imageList)
        setPdf(props.pdfList.pdfList)

        setSelectedParentCategory(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.large_category_id
        )
        setSelectedChildCategory(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.category_id
        )
        setSelectedAssignedCompanyGroup(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.assigned_group_by
        )
        setSelectedAssignedCompanyUser(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.assigned_by
        )
        setSelectedSendCompanyUser(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.sender_id
        )
        setSelectedChatAnswer(
          ChatAnswerList.fromKey(
            props.bulkDeliveryDetailList.bulkDeliveryDetailList
              .answer_button_type
          ).value
        )
        setSelectedSendState(
          BulkDeliverySentStatus.fromKey(
            props.bulkDeliveryDetailList.bulkDeliveryDetailList.sent_status
          ).value
        )
        setThreadMemo(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.thread_memo
        )
      }
    }
  }, [])

  useEffect(() => {
    if (props.bulkDeliveryDetailList == undefined) return
    if (childCategoryList == null) return
    if (props.bulkDeliveryDetailList.bulkDeliveryDetailList != null) {
      setSelectChildCategoryList(
        childCategoryList[
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.large_category_id
        ]
      )
    }
  }, [childCategoryList])

  useEffect(() => {
    if (props.bulkDeliveryDetailList == undefined) return
    if (companyUserMap == null) return
    if (props.bulkDeliveryDetailList.bulkDeliveryDetailList != null) {
      setAssignedCompanyUserList(
        companyUserMap.get(
          props.bulkDeliveryDetailList.bulkDeliveryDetailList.assigned_group_by
        )
      )
    }
  }, [companyUserMap])

  //Dialog
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = state => {
    setOpen(false)
    // テンプレートを保存した場合のみ再度取得する
    if (state == 'succes') {
      fetchTemplateList()
    }
  }

  const insertionText = row => {
    // 改行コード、Bomが含まれている場合があるため
    const replaceRow = '{{' + utilsCsv(row) + '}}'
    var area = document.getElementById('template')
    area.value =
      area.value.substring(0, area.selectionStart) +
      replaceRow +
      area.value.substring(area.selectionStart)
    setTemplateBody(area.value)
  }

  return (
    <FormLayout title="送信メッセージ設定">
      <Typography variant="subtitle1">タイトル</Typography>
      <Grid xs={6}>
        <TextInput
          required
          fullWidth
          id="outlined-basic"
          variant="outlined"
          onChange={handleChangeDeliveryName}
          defaultValue={deliveryName}
        />
      </Grid>
      <FormHelperText
        className={classes.helperText}
        error={validateDeliveryName && validateDeliveryName.hasError}
      >
        {validateDeliveryName && validateDeliveryName.message
          ? validateDeliveryName.message
          : ''}
      </FormHelperText>
      <Typography
        variant="subtitle1"
        className={`${classes.subTitle} ${classes.subTitlePositionChange}`}
      >
        送信メッセージ本文
      </Typography>
      <Grid className={classes.messageSelect}>
        <Grid
          item
          xs={4}
          className={`${classes.item} ${classes.selectTemplate}`}
        >
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="テンプレートを選択"
              label={'テンプレートを選択'}
              onChange={handleChangeTemplate}
              items={templateList}
              value={templateTitle}
              required
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextareaAutosize
          className={classes.body}
          rowsMin="16"
          id="template"
          value={templateBody}
          onChange={handleChangeTemplateBody}
        />
        <FormHelperText
          className={classes.helperText}
          error={validateTemplateBody && validateTemplateBody.hasError}
        >
          {validateTemplateBody && validateTemplateBody.message
            ? validateTemplateBody.message
            : ''}
        </FormHelperText>
      </Grid>
      <Grid
        className={
          csvHeader.length == 0
            ? `${classes.sentenceItem} ${classes.diplayNone}`
            : classes.sentenceItem
        }
      >
        <Typography variant="body1">本文で挿入可能な項目</Typography>
        <Grid>
          <ul className={classes.itemList}>
            {csvHeader.map((row, index) => (
              <Button
                className={classes.itemLi}
                key={index}
                onClick={() => insertionText(row)}
              >
                {row}
              </Button>
            ))}
          </ul>
        </Grid>
      </Grid>

      <div className={classes.attachmentBody}>
        <Grid container spacing={2}>
          <Grid alignItems="center" item xs={6}>
            <Grid className={classes.attachments}>
              <FormLayout title="添付画像" subTitle={'※画像は3つまで添付可'}>
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-image"
                    type="file"
                    onChange={handleChangeImage}
                  />
                </Grid>
                {images &&
                  images.length > 0 &&
                  images.map((row, index) => (
                    <div style={{ marginBottom: 10 }} key={index}>
                      <Image
                        attachment={row}
                        handleDelete={handleDeleteImage(index)}
                      />
                    </div>
                  ))}
                {(images == null || images.length < 3) && (
                  <div>
                    <label
                      htmlFor="contained-button-image"
                      className={classes.labelButton}
                    >
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        color="primary"
                        component="span"
                        startIcon={<AddIcon />}
                      >
                        画像を追加
                      </Button>
                    </label>
                  </div>
                )}
              </FormLayout>
            </Grid>
          </Grid>
          <Grid alignItems="center" item xs={6}>
            <Grid className={classes.attachments}>
              <FormLayout title="添付PDF" subTitle={'※PDFは1つまで添付可'}>
                <Grid item xs={12}>
                  <input
                    accept="application/pdf"
                    className={classes.input}
                    id="contained-button-pdf"
                    type="file"
                    onChange={handleChangePdf}
                  />
                </Grid>
                {pdf ? (
                  <Pdf attachment={pdf} handleDelete={handleDeletePdf} />
                ) : (
                  <label htmlFor="contained-button-pdf">
                    <Button
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="primary"
                      component="span"
                      startIcon={<AddIcon />}
                    >
                      PDFを追加
                    </Button>
                  </label>
                )}
              </FormLayout>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Typography variant="subtitle1" className={classes.subTitle}>
        チャットステータス設定
      </Typography>
      <Grid container className={classes.chatStatus}>
        <Grid item xs={4} className={classes.item}>
          <Typography variant="body2">チャットカテゴリの設定</Typography>
          <Typography
            style={{
              fontFamily: 'Hiragino Sans W1',
              fontSize: '12px'
            }}
          >
            送信するチャットのカテゴリを設定します（必須）
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="大カテゴリー"
              label={'大カテゴリ'}
              onChange={handleChangeParentCategory}
              defaultValue={selectedParentCategory}
              items={parentCategoryList}
              required
            />
          </FormControl>
          <FormHelperText
            className={classes.helperText}
            error={
              validateSelectedParentCategory &&
              validateSelectedParentCategory.hasError
            }
          >
            {validateSelectedParentCategory &&
            validateSelectedParentCategory.message
              ? validateSelectedParentCategory.message
              : ''}
          </FormHelperText>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="中カテゴリー"
              label={'中カテゴリ'}
              onChange={handleChangeChildCategory}
              defaultValue={selectedChildCategory}
              items={selectChildCategoryList}
              required
            />
          </FormControl>
          <FormHelperText
            className={classes.helperText}
            error={
              validateSelectedChildCategory &&
              validateSelectedChildCategory.hasError
            }
          >
            {validateSelectedChildCategory &&
            validateSelectedChildCategory.message
              ? validateSelectedChildCategory.message
              : ''}
          </FormHelperText>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography variant="body2">チャット担当者の設定</Typography>
          <Typography
            style={{
              fontFamily: 'Hiragino Sans W1',
              fontSize: '12px'
            }}
          >
            担当者を設定します。
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="担当グループ"
              label={'担当グループ'}
              onChange={handleChangeAssignedCompanyGroup}
              defaultValue={selectedAssignedCompanyGroup}
              value={selectedAssignedCompanyGroup}
              items={companyGroupList}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="担当者"
              label={'担当者'}
              onChange={handleChangeAssignedCompanyUser}
              defaultValue={selectedAssignedCompanyUser}
              value={selectedAssignedCompanyUser}
              items={assignedCompanyUserList}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography variant="body2">チャット送信者名の設定</Typography>
          <Typography
            style={{
              fontFamily: 'Hiragino Sans W1',
              fontSize: '12px'
            }}
          >
            送信者の表示名を設定します（必須）
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="送信者名"
              label={'送信者名'}
              onChange={handleSelectedSendCompanyUser}
              defaultValue={selectedSendCompanyUser}
              value={selectedSendCompanyUser}
              items={companyUserList}
              required
            />
          </FormControl>
          <FormHelperText
            className={classes.helperText}
            error={
              validateSelectedSendCompanyUser &&
              validateSelectedSendCompanyUser.hasError
            }
          >
            {validateSelectedSendCompanyUser &&
            validateSelectedSendCompanyUser.message
              ? validateSelectedSendCompanyUser.message
              : ''}
          </FormHelperText>
        </Grid>
        <Grid item xs={4} className={classes.item}></Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography variant="body2">チャット回答ボタンの送信</Typography>
          <Typography
            style={{
              fontFamily: 'Hiragino Sans W1',
              fontSize: '12px'
            }}
          >
            メッセージと一緒に回答ボタンを送ります。入居者がボタンを押すと自動的に完了になります。
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Dropdown
              name="チャット回答ボタン"
              label={'チャット回答ボタン'}
              onChange={handleChangeSelectedChatAnswer}
              defaultValue={selectedChatAnswer}
              value={selectedChatAnswer}
              items={ChatAnswerList.values()}
              required
            />
          </FormControl>
          <FormHelperText
            className={classes.helperText}
            error={
              validateSelectedChatAnswer && validateSelectedChatAnswer.hasError
            }
          >
            {validateSelectedChatAnswer && validateSelectedChatAnswer.message
              ? validateSelectedChatAnswer.message
              : ''}
          </FormHelperText>
        </Grid>
        <Grid item xs={4} className={classes.item}></Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography variant="body2">送信後のステータス</Typography>
          <Typography
            style={{
              fontFamily: 'Hiragino Sans W1',
              fontSize: '12px'
            }}
          >
            送信後のステータスを設定します（必須）
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <Dropdown
            name="送信ステータス"
            label={'ステータス'}
            onChange={handleChangeSendState}
            defaultValue={selectedSendState}
            value={selectedSendState}
            items={BulkDeliverySentStatus.values()}
            required
          />
          <FormHelperText
            className={classes.helperText}
            error={
              validateSelectedSendState && validateSelectedSendState.hasError
            }
          >
            {validateSelectedSendState && validateSelectedSendState.message
              ? validateSelectedSendState.message
              : ''}
          </FormHelperText>
        </Grid>
        <Grid item xs={4} className={classes.item}></Grid>
        <Grid item xs={4} className={classes.item}>
          <Typography variant="body2">スレッドメモ</Typography>
          <Typography
            style={{
              fontFamily: 'Hiragino Sans W1',
              fontSize: '12px'
            }}
          >
            送信後のチャットスレッドのメモ欄に記載されます。
          </Typography>
        </Grid>
        <Grid item xs={8} className={classes.item}>
          <TextareaAutosize
            id="chatThreadMemo"
            className={classes.body}
            rowsMin="8"
            onChange={handleChangeThreadMemo}
            defaultValue={threadMemo}
          />
          <FormHelperText
            className={classes.helperText}
            error={validateTreadMemo && validateTreadMemo.hasError}
          >
            {validateTreadMemo && validateTreadMemo.message
              ? validateTreadMemo.message
              : ''}
          </FormHelperText>
          <Typography
            className={
              validateTreadMemo != null
                ? `${classes.treadmemoContentLimitTypeError} ${classes.treadmemoContentLimitStyle}`
                : `${classes.treadmemoContentLimit} ${classes.treadmemoContentLimitStyle}`
            }
          >
            最大300文字
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.templateRegister} xs={12}>
        <Button
          variant="contained"
          className={classes.templateRegisterButton}
          onClick={handleOpenTemplateTitle}
        >
          作成した内容をテンプレートとして保存する
        </Button>
        <CreateFormDialog
          open={open}
          handleClose={handleClose}
          params={param}
        />
        <Typography
          style={{
            fontFamily: 'Hiragino Sans W4',
            fontSize: '13px',
            marginTop: '6px',
            color: '#7d8d9a'
          }}
        >
          テンプレートとして保存する場合はこちら
        </Typography>
      </Grid>
      <Grid container className={classes.rowButtonArea}>
        <Grid item xs={12} className={classes.transitionButtonSet}>
          <Grid className={classes.flexButton} xs={6}>
            <Button className={classes.primaryButton} onClick={handleSubmit}>
              次へ（確認・個別設定）
            </Button>
            <Button
              className={classes.secondaryButton}
              onClick={() => goToReturnPreviousScreen()}
            >
              戻る
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <LoadingWithBackground isLoading={loading} />
    </FormLayout>
  )
}

const mapStateToProps = state => {
  return {
    targetIdList: state.targetIdList.list,
    list: state.csvInformation.list,
    value: state.matchCsvValue.value,
    csvHeader: state.csvHeader.value,
    selectContractList: state.selectContractList.list,
    filterCsvInformation: state.filterCsvInformation.list,
    bulkDeliveryDetailList: state.bulkDeliveryDetailList.list,
    imageList: state.imageList.list,
    pdfList: state.pdfList.list
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectContractList: list => {
      dispatch(setSelectContractList(list))
    },
    setCsvInformation: parameter => {
      dispatch(setCsvInformation(parameter))
    },
    setMatchCsvValue: parameter => {
      dispatch(setMatchCsvValue(parameter))
    },
    setTargetIdList: parameter => {
      dispatch(setTargetIdList(parameter))
    },
    setCsvHeader: parameter => {
      dispatch(setCsvHeader(parameter))
    },
    setFilterCsvInformation: parameter => {
      dispatch(setFilterCsvInformation(parameter))
    },
    setMatchContractIdList: parameter => {
      dispatch(setMatchContractIdList(parameter))
    },
    setBulkDeliveryDetailList: parameter => {
      dispatch(setBulkDeliveryDetailList(parameter))
    },
    setImageList: parameter => {
      dispatch(setImageList(parameter))
    },
    setPdfList: parameter => {
      dispatch(setPdfList(parameter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditContent))
