import React, { useEffect, useState } from 'react'
import { QueryHelper } from 'utils/api.utils'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Loading from 'components/common/Loading'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import { formatYMD, formatYYYYMMDD } from 'utils/date.utils'
import Link from '@material-ui/core/Link'
import { handleOpenPageNewTab } from 'actions/Actions'
import { useLoginInformation } from 'hooks/useLoginInformation'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'
import UseUseLabel from 'components/common/UseUseLabel'
import UseOwnerLabel from 'components/common/UseOwnerLabel'
import { ErrorMessages } from 'utils/errorMessages'

const EXTERNAL_USER = 5

const useStyles = makeStyles(() => ({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  },
  section: {
    width: '350px',
    padding: '15px 0px 15px 20px'
  },
  section2: {
    padding: '15px 20px'
  },
  section3: {
    width: '350px',
    padding: '0px 0px 15px 20px'
  },
  link: {
    color: '#696CFF',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  text: {
    fontFamily: 'Hiragino Sans',
    fontSize: '14px'
  },
  columnName2: {
    width: '100px',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  otherBuildingWrapper: {
    paddingTop: '15px'
  }
}))

const ResidentTabs = props => {
  const classes = useStyles()
  const application = props.application ? props.application : null
  const customer = props.customer ? props.customer : null
  const contract = props.contract ? props.contract : null
  const thread = props.thread ? props.thread : null
  const [tenantContract, setTenantContracts] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [building, setBuilding] = useState({})
  const [ownerBuilding, setOwnerBuilding] = useState()
  const [contractList, setContractList] = useState({})
  const { judgmentRoleId } = useLoginInformation()
  const { useCheckOwnerPath } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()

  // アプリの利用状況を表示するために必要
  const fetchBuilding = async () => {
    if (!application || !application.building_id) {
      return
    }
    const params = {
      id: {
        eq: application.building_id
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('建物情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuilding(result.building)
    }
  }

  const fetchContractList = async tenantId => {
    const params = {
      tenant_id: {
        eq: tenantId
      }
    }
    const result = await QueryHelper('getContractList2', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`契約物件情報を取得できませんでした。`, {
        variant: 'error'
      })
    } else {
      await Promise.all([setContractList(result.data)])
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([props.fetchCustomerData()])
    setIsLoading(false)
  }

  const formatUserName = userName => {
    const s = userName.split('')
    let a = []
    for (let i = 0; i < s.length; i++) {
      if (i % 15 == 0) {
        a.push('\n')
        a.push(s[i])
      } else {
        a.push(s[i])
      }
    }
    return a.join('')
  }

  const fetchOwnerBuilding = async () => {
    if (
      !customer ||
      !customer.owner ||
      !customer.owner.id ||
      !building ||
      !building.id
    ) {
      return
    }
    const result = await QueryHelper('getOwnerBuilding', {
      owner_id: customer.owner.id,
      building_id: building.id
    })
    if (result.error) {
      props.enqueueSnackbar(ErrorMessages.FailedToRetrieveOwnerInfo, {
        variant: 'error'
      })
    } else {
      setOwnerBuilding(result)
    }
  }

  useEffect(() => {
    fetchData()
  }, [application])

  useEffect(() => {
    fetchBuilding()
  }, [tenantContract])

  useEffect(() => {
    if (isOwnerPath || customer.customer == null) return
    fetchContractList(customer.customer.id)
    setTenantContracts(customer.tenantContracts[0])
  }, [customer])

  useEffect(() => {
    fetchOwnerBuilding()
  }, [customer, building])

  return (
    <>
      <Grid className={classes.section2}>
        {isOwnerPath ? (
          <Grid container spacing={4}>
            <Grid item xs={10} alignItems="center" spacing={2}>
              <Typography className={classes.title}>
                {'オーナー情報'}
              </Typography>
            </Grid>
            {application && application.customer_id && (
              <IconButton
                aria-label="new"
                onClick={() =>
                  handleOpenPageNewTab(
                    `/owner/detail/${customer.owner.id}/building/${thread.building_id}`
                  )
                }
              >
                <OpenInNewIcon />
              </IconButton>
            )}
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={10} alignItems="center" spacing={2}>
              <Typography className={classes.title}>{'入居者情報'}</Typography>
            </Grid>
            {application &&
              application.customer_id &&
              judgmentRoleId() !== EXTERNAL_USER && (
                <IconButton
                  aria-label="new"
                  onClick={() =>
                    handleOpenPageNewTab(
                      `/management/contract/detail/${contract.id}`
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              )}
          </Grid>
        )}
      </Grid>
      <Divider />
      {!isLoading && customer ? (
        <>
          <Grid className={classes.section2}>
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className={classes.text}>
                  アプリの状態
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {isOwnerPath ? (
                    ownerBuilding && (
                      <UseOwnerLabel is_usage={ownerBuilding.is_usage} />
                    )
                  ) : customer.customer && customer.kYKNKYKKYIDKNRKEIYAKUS ? (
                    <UseUseLabel
                      is_usage={customer.customer.is_usage}
                      is_management={building.is_management}
                      app_user_id={customer.customer.app_user_id}
                      kykStatusDiv={
                        customer.kYKNKYKKYIDKNRKEIYAKUS[0].KYK_STATUS_DIV
                      }
                    />
                  ) : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {isOwnerPath ? (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      {'オーナー名'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12}>
                      <Link
                        onClick={() =>
                          handleOpenPageNewTab(
                            `/owner/detail/${customer.owner.id}/building/${thread.building_id}`
                          )
                        }
                        className={classes.link}
                      >
                        <Typography variant="body2" className={classes.text}>
                          {customer.owner && customer.owner.name}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      フリガナ
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer.owner && customer.owner.name_kana}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      {'入居者名'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12}>
                      {judgmentRoleId() !== EXTERNAL_USER ? (
                        <Link
                          onClick={() =>
                            handleOpenPageNewTab(
                              `/management/contract/detail/${contract.id}`
                            )
                          }
                          className={classes.link}
                        >
                          <Typography variant="body2" className={classes.text}>
                            {customer.mstkokyaku &&
                              customer.mstkokyaku.KKY_NAME &&
                              customer.mstkokyaku.KKY_NAME}
                          </Typography>
                        </Link>
                      ) : (
                        <Typography variant="body2" className={classes.text}>
                          {customer.mstkokyaku &&
                            customer.mstkokyaku.KKY_NAME &&
                            customer.mstkokyaku.KKY_NAME}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      フリガナ
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer &&
                        customer.mstkokyaku &&
                        customer.mstkokyaku.KKY_NAME_KANA &&
                        customer.mstkokyaku.KKY_NAME_KANA}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Divider />
            {!isOwnerPath && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      携帯電話番号
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer &&
                        customer.mstkokyaku &&
                        customer.mstkokyaku.KKY_TEL_02 &&
                        customer.mstkokyaku.KKY_TEL_02}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </>
            )}
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className={classes.text}>
                  メール（ID）
                </Typography>
              </Grid>
              {/* 空白がない文字列は改行されないのでフォーマットする必要がある */}
              {isOwnerPath ? (
                <Grid item xs={12} sm={6} container>
                  <Typography variant="body2" className={classes.text}>
                    {customer.app_user_and_attachment &&
                      customer.app_user_and_attachment.username &&
                      formatUserName(customer.app_user_and_attachment.username)}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} container>
                  <Typography variant="body2" className={classes.text}>
                    {customer.appUser &&
                      customer.appUser.username &&
                      formatUserName(customer.appUser.username)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Divider />
            {!isOwnerPath && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      生年月日
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer.mstkokyaku && customer.mstkokyaku.KKY_BIRTHDAY
                        ? formatYMD(new Date(customer.mstkokyaku.KKY_BIRTHDAY))
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      職業
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer &&
                        customer.mstkokyaku &&
                        customer.mstkokyaku.KKY_SKG_NAME &&
                        customer.mstkokyaku.KKY_SKG_NAME}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      契約者名
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer.kYKNKYKKYIDKNRKEIYAKUS &&
                        customer.kYKNKYKKYIDKNRKEIYAKUS[0].KYK_KYS_KKY_NAME &&
                        customer.kYKNKYKKYIDKNRKEIYAKUS[0].KYK_KYS_KKY_NAME}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  className={classes.row}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" className={classes.text}>
                      法人/個人
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Typography variant="body2" className={classes.text}>
                      {customer.mstkokyaku &&
                        customer.mstkokyaku.KKY_LEGALPERSON_DIV_NAME &&
                        customer.mstkokyaku.KKY_LEGALPERSON_DIV_NAME}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </>
            )}
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className={classes.text}>
                  {isOwnerPath ? '作成日' : 'アプリ使用開始日'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {isOwnerPath
                    ? customer.app_user_and_attachment &&
                      customer.app_user_and_attachment.registered_at
                      ? formatYYYYMMDD(
                          new Date(
                            customer.app_user_and_attachment.registered_at
                          )
                        )
                      : null
                    : customer.appUser && customer.appUser.registered_at
                    ? formatYYYYMMDD(new Date(customer.appUser.registered_at))
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid item xs={12} container spacing={2} className={classes.row}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" className={classes.text}>
                  最終ログイン日
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Typography variant="body2" className={classes.text}>
                  {isOwnerPath
                    ? customer.app_user_and_attachment &&
                      customer.app_user_and_attachment.last_login_at
                      ? formatYYYYMMDD(
                          new Date(
                            customer.app_user_and_attachment.last_login_at
                          )
                        )
                      : null
                    : customer.appUser && customer.appUser.last_login_at
                    ? formatYYYYMMDD(new Date(customer.appUser.last_login_at))
                    : null}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          {isOwnerPath
            ? customer.mst_tateya_and_building &&
              customer.mst_tateya_and_building.length > 1 && (
                <>
                  <Grid className={classes.section2}>
                    <Grid container spacing={4}>
                      <Grid item xs={10} alignItems="center" spacing={2}>
                        <Typography className={classes.title}>
                          {'その他所有物件'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />

                  {customer.mst_tateya_and_building.map((row, index) => {
                    if (row.building.id === building.id) {
                      return <></>
                    }
                    return (
                      <Grid className={classes.section3} key={index}>
                        <Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={2}
                            className={`${classes.row} ${classes.otherBuildingWrapper}`}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                物件名
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Grid item xs={12}>
                                <Link
                                  onClick={() =>
                                    handleOpenPageNewTab(
                                      `/management/building/${row.building.id}`
                                    )
                                  }
                                  className={classes.link}
                                >
                                  <Typography
                                    variant="body2"
                                    className={classes.text}
                                  >
                                    {row.mst_tateya.TTY_NAME}
                                  </Typography>
                                </Link>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              )
            : contractList &&
              contractList.length > 1 && (
                <>
                  <Grid className={classes.section2}>
                    <Grid container spacing={4}>
                      <Grid item xs={10} alignItems="center" spacing={2}>
                        <Typography className={classes.title}>
                          {'その他契約物件'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />

                  {contractList.map((row, index) => {
                    // TODO: 別の条件分岐で実装可能なのかAPIが出来次第確認する
                    if (row.building_id === building.id) {
                      return <></>
                    }
                    return (
                      <Grid className={classes.section3} key={index}>
                        <Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={2}
                            className={`${classes.row} ${classes.otherBuildingWrapper}`}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                物件名
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Grid item xs={12}>
                                {judgmentRoleId() !== EXTERNAL_USER ? (
                                  <Link
                                    onClick={() =>
                                      handleOpenPageNewTab(
                                        `/management/building/${row.building.id}`
                                      )
                                    }
                                    className={classes.link}
                                  >
                                    <Typography
                                      variant="body2"
                                      className={classes.text}
                                    >
                                      {row.mst_tateya.TTY_NAME}
                                    </Typography>
                                  </Link>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    className={classes.text}
                                  >
                                    {row.mst_tateya.TTY_NAME}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={2}
                            className={classes.row}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                部屋番号
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} container>
                              <Typography
                                variant="body2"
                                className={classes.text}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Grid item xs={12}>
                                    {judgmentRoleId() !== EXTERNAL_USER ? (
                                      <Link
                                        onClick={() =>
                                          handleOpenPageNewTab(
                                            `/management/building/${row.building.id}/room/${row.contract.room_id}`
                                          )
                                        }
                                        className={classes.link}
                                      >
                                        <Typography
                                          variant="body2"
                                          className={classes.text}
                                        >
                                          {row.mst_heya.HYA_ROOM_NO}
                                        </Typography>
                                      </Link>
                                    ) : (
                                      <Typography
                                        variant="body2"
                                        className={classes.text}
                                      >
                                        {row.mst_heya.HYA_ROOM_NO}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                      </Grid>
                    )
                  })}
                </>
              )}
        </>
      ) : (
        <Grid item xs={12} container spacing={1} className={classes.row}>
          <Grid item xs={12} sm={12}>
            <Loading isLoading={isLoading} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default withSnackbar(withRouter(ResidentTabs))
