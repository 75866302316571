export const ErrorMessages = {
  FailedToSave: '保存に失敗しました',
  Required: '必須項目です',
  IncorrectKanaFormat: 'カタカナで入力してください',
  IncorrectEmailAddressFormat: 'メールアドレスの形式が不正です',
  IncorrectPasswordFormat:
    '8文字以上で、英大文字、英小文字、数字を含むようにしてください',
  EmailAddressExcessInput: 'メールアドレスは254文字以下で入力してください',
  FailedToRetrieveCategory: 'カテゴリーを取得できませんでした。',
  FailedToAuthenticateIPAddress: 'IPアドレス認証に失敗しました',
  FailedToRetrieveSettings: '設定情報を取得できませんでした。',
  UrlError: 'URLの形式が不正です',
  ConfirmPasswordMismatch: 'パスワードが一致しません',
  BodyExcessInput: '1文字以上、1000文字以下で入力してください',
  DayExcessInput: '1日以上、365日以下で入力してください',
  CharLimit30: '30文字以内で入力してください',
  CharLimit100: '100文字以内で入力してください',
  CharLimit256: '256文字以内で入力してください',
  FailedToRetrieveOwnerInfo: 'オーナー情報を取得できませんでした。'
}
