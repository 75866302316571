import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { TableContainer } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { ContractKykStatusDiv } from '../../../../utils/enum'
import Label from '../../../../components/common/Label'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import AddIcon from '@material-ui/icons/Add'
import TablePagination from '@material-ui/core/TablePagination'
import { PAGE_LIMIT } from 'utils/const'
import { Auth, Storage } from 'aws-amplify'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import { withSnackbar } from 'notistack'
import Dropdown from 'components/common/Form/Dropdown'
import FormControl from '@material-ui/core/FormControl'
import Image from './Image'
import { QueryHelper, MutationHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'
import uuid from 'uuid/v4'
import Pdf from './Pdf'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import { connect } from 'react-redux'
import { closeSearchModalDialog } from 'actions/Actions'
import CommonChip from 'components/common/CommonChip'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  Wrapper: {
    padding: '12px'
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  titleWrapper: {
    display: 'flex'
  },

  titleLayout: {
    fontSize: '30px',
    paddingTop: '10px'
  },

  subTitle: {
    paddingTop: '30px'
  },

  subTitleLayout: {
    fontSize: '18px'
  },

  titleResultsDisplay: {
    paddingLeft: '150px'
  },

  border: {
    border: 'solid 1px rgba(158, 158, 158, 0.5)'
  },

  tableWrapper: {
    paddingRight: '20px'
  },

  tableContainer: {
    maxHeight: '1200px',
    minHeight: '1200px'
  },

  tableRowTypeBlue: {
    backgroundColor: theme.overrides.MuiTableCell.stickyHeader.backgroundColor
  },

  propertyWidth: {
    maxWidth: '70px'
  },

  usageSituationButtonTypeGreen: {
    backgroundColor: theme.palette.c_green.main,
    '&:hover': {
      backgroundColor: theme.palette.c_green.main
    }
  },

  name: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },

  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },

  rightContent: {
    padding: '5px 2px 0px 2px'
  },

  body: {
    width: '100%',
    minHeight: '555px',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '16px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W3',
    lineHeight: '24px'
  },

  messegeTitleWrapper: {
    height: '50px',
    display: 'table-cell',
    verticalAlign: 'middle'
  },

  uploadTitle: {
    paddingTop: '20px',
    paddingBottom: '10px'
  },

  uploadImageTitle: {
    paddingTop: '70px',
    paddingBottom: '10px'
  },

  uploadAreaWrapper: {
    position: 'relative'
  },

  uploadArea: {
    height: '80px'
  },

  uploadImageArea: {
    height: '250px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },

  uploadContentWrapper: {
    padding: '10px'
  },

  editButtonWrapper: {
    paddingTop: '90px',
    paddingBottom: '15px',
    textAlign: 'center'
  },

  saveButtonWrapper: {
    paddingTop: '90px',
    paddingBottom: '15px',
    textAlign: 'center'
  },

  textareaAutosize: {
    color: '#72809d'
  },

  editTextareaAutosize: {
    color: 'black'
  },

  notEditTextareaAutosize: {
    color: '#72809d'
  },

  input: {
    display: 'none'
  },

  labelImageButtonWrapper: {
    position: 'relative'
  },

  labelButtonPosition: {
    width: '100%',
    position: 'absolute'
  },

  documentButtonTopPosition: {
    top: '90px'
  },

  imageButtonTopPosition: {
    top: '10px'
  },

  setDisplayWrapper: {
    paddingTop: '20px'
  },

  chatStatusArea: {
    maxHeight: '420px',
    height: '420px',
    // 画面幅が小さくなった時にスクロール
    overflowY: 'scroll',
    overflowX: 'hidden'
  },

  chatStatusContentTitle: {
    paddingBottom: '5px'
  },

  setDisplayTitle: {
    paddingTop: '10px',
    paddingLeft: '5px'
  },

  chatStatusContent: {
    fontWeight: '600',
    fontSize: '16px'
  },

  transmissionDateSetArea: {
    height: '300px',
    display: 'flex',
    paddingTop: '20px',
    paddingLeft: '10px'
  },

  selectWrapper: {
    paddingLeft: '100px'
  },

  formatdayWrapper: {
    paddingLeft: '50px',
    paddingTop: '20px'
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '50px',
    paddingBottom: '50px'
  },

  CsvButtonWrapper: {
    paddingRight: '30px'
  },

  ReturnButtonWrapper: {
    paddingRight: '30px'
  },

  button: {
    color: theme.palette.c_white.main
  },

  usageSituationButtonTypeBlue: {
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },

  usageSituationButtonTypeBarkBlue: {
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  },

  usageSituationButtonTypeGray: {
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    }
  },

  usageSituationReturnButtonTypeGray: {
    color: theme.palette.c_light_gray.contrastText,
    backgroundColor: theme.palette.c_light_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_light_gray.dark
    }
  },

  tableRowTypeDefault: {
    backgroundColor: theme.palette.c_background.gray
  },

  selectTitle: {
    color: 'gray',
    position: 'relative',
    top: '25px',
    left: '10px',
    fontSize: '12px'
  },

  errorSelectTitle: {
    color: 'red'
  },

  selectLayout: {
    position: 'relative',
    top: '20px',
    appearance: 'none',
    mozAppearance: 'none',
    webkitAppearance: 'none',
    background: 'none',
    border: 'none',
    color: '#333',
    fontSize: '18px',
    paddingTop: '10px',
    paddingLeft: '10px',
    fontWeight: 'bold'
  },

  selectWidthLeft: {
    width: '14%'
  },

  selectWidthRight: {
    width: '20%'
  },

  selectBackGround: {
    height: '60px',
    backgroundColor: theme.palette.c_light_gray.main,
    borderRadius: '5px 5px 5px 5px'
  },

  comma: {
    position: 'relative',
    top: '18px'
  },

  errorMessage: {
    position: 'relative',
    top: '30px',
    paddingLeft: '13px',
    fontSize: '12px',
    color: 'red'
  },
  tertiaryButton: {
    color: theme.palette.c_gray.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    position: 'relative',
    top: '88px'
  },
  paperWrapper: {
    paddingBottom: '20px'
  }
}))

const columns = [
  { label: '編集', width: 100 },
  { label: '状態', width: 120 },
  { label: '契約ID', width: 120 },
  { label: '入居者', width: 200 },
  { label: '物件名', width: 200 }
]

const selectTimeHh = []
for (let i = 0; i <= 23; i++) {
  selectTimeHh.push(i)
}

const selectTimeMm = ['00', 15, 30, 45]

function ConfirmBulkDelivery(props) {
  const classes = useStyles()
  const history = useHistory()
  const [buttonState, setButtonState] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [displayMessege, setDisplayMessege] = useState(null)
  const [selectedVal, setSelectedVal] = useState(0)
  const [selectDay, setSelectDay] = useState(new Date())
  const [formatDay, setFormatDay] = useState('')
  const [deletedImages, setDeletedImages] = useState([])
  const [dataLinkTypeList] = useState([
    { value: 0, label: '今すぐ' },
    { value: 1, label: '日時指定' }
  ])
  const [bulkDeliveryMessages, setBulkDeliveryMessages] = useState([])
  const [clickUserInformation, setClickUserInformation] = useState(null)
  const [images, setImages] = useState([])
  const [pdf, setPdf] = useState(null)
  const [errorMessageDate, setErrorMessageDate] = useState(null)
  const [errorMessageTime, setErrorMessageTime] = useState(null)
  const [errorDateState, setErrorDateState] = useState(null)
  const [errorTimeState, setErrorTimeState] = useState(null)
  const [transmissionStatus, setTransmissionStatus] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [selectTable, setSelectTable] = useState(null)
  const [deletedPdf, setDeletedPdf] = useState(null)
  const [isLoadingTypeTable, setIsLoadingTypeTable] = useState(false)
  const location = useLocation()
  const list_id = location.pathname.replace(/[^0-9]/g, '')
  const [hh, setHh] = useState(null)
  const [mm, setMm] = useState(null)
  const [bulkDeliveryMessagesCount, setBulkDeliveryMessagesCount] = useState(0)
  const [selectedDeliveryList, setSelectedDeliveryList] = useState([])
  const [buildingList, setBuildingList] = useState([])
  const [searchConditions, setSearchConditions] = useState([])

  const toDoubleDigits = num => {
    num += ''
    if (num.length === 1) {
      num = '0' + num
    }
    return num
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }

  const handleChangeMessege = event => {
    setDisplayMessege(event.target.value)
  }

  const changeButtonState = () => {
    buttonState ? setButtonState(false) : setButtonState(true)
  }

  const handleChangePdf = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setPdf(file)
    }
    reader.readAsDataURL(file)
  }

  const handleDeletePdf = event => {
    if (pdf && pdf.id) {
      setDeletedPdf(pdf)
    }
    setPdf(null)
  }

  const handleSelectedItem = e => {
    setSelectedVal(e.target.value)
    setTransmissionStatus(e.target.value)
    // 送信日時を選択する場合
    if (selectedVal == 0) {
      formatDate(new Date(), 'selectTime')
    }
  }

  const handleChangeCreatedAtDate = date => {
    setSelectDay(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), hh, mm)
    )
  }

  const handleDeleteImage = index => event => {
    const splicedImages = [...images.filter((item, i) => i !== index)]
    document.getElementById('contained-button-image').value = null
    if (images && images.length > 0 && images[index].id) {
      const temp = [...deletedImages]
      temp.push(images[index])
      setDeletedImages([...temp])
    }
    setImages([...splicedImages])
  }

  const handleChangeImage = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setImages([...images, file])
    }
    reader.readAsDataURL(file)
  }

  const formatDate = (day, state) => {
    const yyyy = toDoubleDigits(day.getFullYear())
    const MM = toDoubleDigits(day.getMonth() + 1)
    const dd = toDoubleDigits(day.getDate())
    let HH = toDoubleDigits(day.getHours())
    let mm = toDoubleDigits(day.getMinutes())
    setHh(HH)
    setMm(mm)
    if (state == 'display') {
      return setFormatDay(yyyy + '/' + MM + '/' + dd + ' ' + HH + ':' + mm)
    }
    // 日時選択を選択して現在の分が45分を超えていた場合
    if (state == 'selectTime') {
      mm = formatMM(mm)
      setMm(mm)
      if (mm == 0) {
        HH = Number(HH) + 1
        setHh(HH)
      }
      setSelectDay(new Date(yyyy, MM - 1, dd, HH, mm))
      return setFormatDay(yyyy + '/' + MM + '/' + dd + ' ' + HH + ':' + mm)
    }
    return yyyy + '-' + MM + '-' + dd + 'T' + HH + ':' + mm
  }

  const formatMM = (mm, state) => {
    if (mm == 0) {
      return 0
    } else if (mm <= 15) {
      return 15
    } else if (mm > 15 && mm <= 30) {
      return 30
    } else if (mm > 30 && mm <= 45) {
      return 45
    } else if (mm > 45 && mm <= 59) {
      if (state == 'display') {
        return 0
      }
      return '00'
    }
  }

  const displayConfirm = type => {
    if (
      type == 'return' &&
      props.location.state.referrer == '/message/bulk-delivery/edit'
    ) {
      const result = window.confirm(
        `配信内容設定画面に戻ります。個別の設定内容が全て失われますがよろしいですか？`
      )
      if (!result) return
      helpTransition('edit')
    }
    if (
      type == 'return' &&
      props.location.state.referrer == '/message/bulk-delivery/list'
    ) {
      const result = window.confirm(
        `メッセージ一括配信リストに戻ります。個別の設定内容が全て失われますがよろしいですか？`
      )
      if (!result) return
      helpTransition('edit')
    }
    // 今すぐ配信の場合は時間のバリデーションをかけない
    if (transmissionStatus == 1) {
      if (validateForm()) {
        props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
          variant: 'warning'
        })
        return
      }
    }
    if (type == 'saveDraft') {
      const result = window.confirm(`下書きを保存しますがよろしいですか？`)
      if (!result) return
      createBulkDeliveryMessagesDraft()
    }
    if (type == 'reservationCancellation') {
      const result = window.confirm(`配信予約を中止にしますがよろしいですか？`)
      if (!result) return
      deleteBulkDeliveryMessages()
    }
    if (type == 'send') {
      if (selectedVal == 0) {
        const result = window.confirm(
          `送信日時を「今すぐ」で${selectedDeliveryList.send_counts}件に配信しますがよろしいですか？`
        )
        if (!result) return
        sendBulkDeliveryMessages()
      }
      if (selectedVal == 1) {
        const result = window.confirm(
          `送信日時を${formatDay}で${selectedDeliveryList.send_counts}件に配信しますがよろしいですか？`
        )
        if (!result) return
        reserveBulkDeliveryMessages()
      }
    }
  }

  const helpTransition = display => {
    switch (display) {
      case 'edit': {
        history.goBack()
        break
      }
      case 'list': {
        history.push('/message/bulk-delivery/list')
        break
      }
      case 'confirm': {
        history.push(`/message/bulk-delivery/confirm/${list_id}`)
        break
      }
    }
  }

  const fetchMessageList = async (offset, limit) => {
    const filter = {}
    filter['list_id'] = {
      eq: list_id
    }
    Object.keys(searchConditions).forEach(function(key) {
      if (searchConditions[key]) {
        switch (key) {
          case 'kyk_id': {
            filter.kyk_id = { eq: Number(searchConditions[key]) }
            break
          }
          case 'resident_name': {
            filter.resident_name = { contains: searchConditions[key] }
            break
          }
          case 'building_name': {
            const building_id = []
            const buildings = JSON.parse(searchConditions[key])
            for (let building of buildings) {
              building_id.push(building.id)
            }
            if (building_id.length > 0) {
              filter.building_id = { eq: building_id }
            }
            break
          }
          default: {
            break
          }
        }
      }
    })
    let result = await QueryHelper(
      'getBulkDeliveryMessages',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('メッセージ情報の取得に失敗しました', {
        variant: 'error'
      })
    } else {
      setBulkDeliveryMessagesCount(result.paging.count)
      setBulkDeliveryMessages(result.data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [searchConditions])

  const fetchBulkDeliveryList = async (offset, limit) => {
    const filter = {}
    filter['id'] = {
      eq: list_id
    }
    let result = await QueryHelper(
      'getBulkDeliveryList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('配信リスト情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      if (result.data.length == 0) return
      setSelectedDeliveryList(result.data[0])
    }
  }

  const fetchBuildingList = async () => {
    let result = await QueryHelper('getBuildingNameList', {})
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('物件一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuildingList(result.data)
    }
  }

  const reflectReservationDetails = data => {
    setSelectedVal(1)
    setTransmissionStatus(1)
    setSelectDay(new Date(data))
    setFormatDay(data.substring(11, 16))
  }

  const createBulkDeliveryMessagesDraft = async (offset, limit) => {
    const params = {}
    params['list_id'] = list_id
    params['send_date_setting'] = selectedVal == 0 ? 'NOW' : 'RESERVE'
    if (selectedVal != 0) {
      params['send_date'] = selectDay
    }
    let result = await MutationHelper('createBulkDeliveryMessagesDraft', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('下書きを保存できませんでした', {
        variant: 'error'
      })
      helpTransition('confirm')
    } else {
      props.enqueueSnackbar('下書きを保存しました', {
        variant: 'success'
      })
      helpTransition('list')
    }
  }

  const updateBulkDeliveryMessage = async (offset, limit) => {
    setIsLoading(true)
    const params = {}
    params['body'] = displayMessege
    params['message_id'] = clickUserInformation.id
    const attachments = []

    const creds = await Auth.currentCredentials()
    if (pdf && !pdf.id) {
      const result = await fileupload(creds, 'pdfs', pdf)
      attachments.push({
        ['attachment_type_id']: 3,
        ['mime_type']: pdf.type,
        ['filename']: pdf.name,
        ['key']: result.key
      })
    }
    if (images && images.length > 0) {
      for (let image of images) {
        if (!image.id) {
          const result = await fileupload(creds, 'images', image)
          attachments.push({
            ['attachment_type_id']: 1,
            ['mime_type']: image.type,
            ['filename']: image.name,
            ['key']: result.key
          })
        }
      }
    }

    if (attachments.length > 0) {
      params.attachments = attachments
    }

    if ((deletedImages && deletedImages.length > 0) || deletedPdf) {
      if (!params.attachments) {
        params.attachments = []
      }
      if (deletedImages && deletedImages.length > 0) {
        if (!params.attachments) {
          params.attachments = []
        }
        for (let image of deletedImages) {
          if (image.id) {
            params.attachments.push({
              id: image.id,
              key: image.key,
              status: 3
            })
          }
        }
      }

      if (deletedPdf) {
        if (!params.attachments) {
          params.attachments = []
        }
        params.attachments.push({
          id: deletedPdf.id,
          key: deletedPdf.key,
          status: 3
        })
      }
    }

    let result = await MutationHelper('updateBulkDeliveryMessage', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('編集内容を保存できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('編集内容を保存しました', {
        variant: 'success'
      })
      fetchMessageList()
    }
    buttonState ? setButtonState(false) : setButtonState(true)
    setIsLoading(false)
  }

  const deleteBulkDeliveryMessages = async (offset, limit) => {
    const params = {}
    params['list_id'] = list_id
    let result = await MutationHelper('deleteBulkDeliveryMessages', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('配信予約を中止できませんでした', {
        variant: 'error'
      })
      helpTransition('confirm')
    } else {
      props.enqueueSnackbar('配信予約を中止しました', {
        variant: 'success'
      })
      helpTransition('list')
    }
  }

  const reserveBulkDeliveryMessages = async (offset, limit) => {
    const params = {}
    params['list_id'] = list_id
    params['send_date_setting'] = 'RESERVE'
    params['send_date'] = selectDay
    let result = await MutationHelper('reserveBulkDeliveryMessages', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('予約できませんでした', {
        variant: 'error'
      })
      helpTransition('confirm')
    } else {
      props.enqueueSnackbar('予約しました', {
        variant: 'success'
      })
      helpTransition('list')
    }
  }

  const sendBulkDeliveryMessages = async (offset, limit) => {
    const params = {}
    params['list_id'] = list_id
    let result = await MutationHelper('sendBulkDeliveryMessages', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('配信ができませんでした', {
        variant: 'error'
      })
      helpTransition('confirm')
    } else {
      props.enqueueSnackbar('配信を開始しました', {
        variant: 'success'
      })
      helpTransition('list')
    }
  }

  const changeDisplayedInformation = (data, index) => {
    // 編集モード中に他入居者の行をタップした場合編集モードは解除 変更内容はキャンセル
    if (clickUserInformation) {
      if (clickUserInformation.id == data.id) {
        return
      }
    }
    setDisplayMessege(data.body)
    setButtonState(false)
    setClickUserInformation(data)
    setSelectTable(index)
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  const fetchData = async offset => {
    setIsLoadingTypeTable(true)
    await Promise.all([fetchMessageList(offset, PAGE_LIMIT)])
    setIsLoadingTypeTable(false)
  }

  function KeiyakuStatusLabel({ kykStatusDiv }) {
    if (kykStatusDiv == 'APPLICATION') {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.application.value} />
      )
    } else if (kykStatusDiv == 'CONTRACTING') {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.contract.value} />
      )
    } else if (kykStatusDiv == 'PLANNING_CANCEL') {
      return (
        <Label color={'green'} text={ContractKykStatusDiv.cancellation.value} />
      )
    } else {
      return <Label color={'gray'} text={ContractKykStatusDiv.cancel.value} />
    }
  }

  const responseDetermination = value => {
    switch (value) {
      case 'AGREE':
        return '同意します'
      case 'CONFIRM':
        return '確認しました'
      case 'NOTHING':
        return 'なし'
      case 'NOT_COMPATIBLE':
        return '未対応'
      case 'RESPONDING':
        return '対応中'
      case 'SUPPORTED':
        return '対応済'
      case 'COMPLETE':
        return '完了'
      case null:
        return ''
    }
  }

  function validateForm() {
    let isError = false
    const today = new Date()
    const validationToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes()
    )
    const validationSelectDay = new Date(
      selectDay.getFullYear(),
      selectDay.getMonth(),
      selectDay.getDate(),
      selectDay.getHours(),
      selectDay.getMinutes()
    )

    if (selectDay == 'Invalid Date') {
      return validateSelectTime('Invalid Date')
    }
    if (validationToday > validationSelectDay == true) {
      if (validationToday.getFullYear() > validationSelectDay.getFullYear()) {
        return validateSelectTime(isError, 'day')
      }
      if (validationToday.getFullYear() == validationSelectDay.getFullYear()) {
        if (validationToday.getMonth() > validationSelectDay.getMonth()) {
          return validateSelectTime(isError, 'day')
        }
      }
      if (validationToday.getDate() > validationSelectDay.getDate()) {
        return validateSelectTime(isError, 'day')
      }
      if (validationToday.getHours() > validationSelectDay.getHours()) {
        setErrorDateState(false)
        setErrorMessageDate(null)
        return validateSelectTime(isError, 'time')
      }
      if (validationToday.getMinutes() > validationSelectDay.getMinutes()) {
        setErrorDateState(false)
        setErrorMessageDate(null)
        return validateSelectTime(isError, 'time')
      }
    }

    setErrorMessageDate(null)
    setErrorMessageTime(null)
    setErrorDateState(false)
    setErrorTimeState(false)
    return (isError = false)
  }

  const validateSelectTime = (isError, value) => {
    switch (value) {
      case 'day':
        setErrorMessageDate('本日より前の日にちは指定できません')
        setErrorDateState(true)
        break
      case 'time':
        setErrorMessageTime('過ぎた時間は指定できません')
        setErrorTimeState(true)
        break
      default:
        setErrorMessageDate('想定外のエラーが発生しました')
        setErrorMessageTime('想定外のエラーが発生しました')
        setErrorDateState(true)
        setErrorTimeState(true)
    }
    return (isError = true)
  }

  const handleTimeChangeHH = event => {
    const index = Number(event.target.value)
    setSelectDay(
      new Date(
        selectDay.getFullYear(),
        selectDay.getMonth(),
        selectDay.getDate(),
        index,
        mm
      )
    )
  }

  const handleTimeChangeMM = event => {
    const index = Number(event.target.value)
    setSelectDay(
      new Date(
        selectDay.getFullYear(),
        selectDay.getMonth(),
        selectDay.getDate(),
        hh,
        index
      )
    )
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[1].length != 0) {
        params[entry[0]] = entry[1]
      }
    }
    props.closeSearchModalDialog()
    setSearchConditions(params)
  }

  const handleDeleteSearchConditions = (target, id = null) => () => {
    const params = JSON.parse(JSON.stringify(searchConditions))
    switch (target) {
      case 'kyk_id': {
        delete params[target]
        break
      }
      case 'resident_name': {
        delete params[target]
        break
      }
      case 'building_name': {
        const buildings = JSON.parse(searchConditions[target])
        const trimBuildings = buildings.filter(building => building.id !== id)
        if (trimBuildings.length > 0) {
          params[target] = JSON.stringify(trimBuildings)
        } else {
          delete params[target]
        }
        break
      }
    }
    setSearchConditions(params)
  }

  useEffect(() => {
    if (clickUserInformation === null) return
    let images = []
    setPdf(null)
    setImages([])
    if (clickUserInformation.attachments == null) return
    for (let attachment of clickUserInformation.attachments) {
      if (attachment.status !== 3) {
        if (attachment.attachment_type_id === 1) {
          images.push(attachment)
          setImages(images)
        } else if (attachment.attachment_type_id === 3) {
          setPdf(attachment)
        }
      }
    }
  }, [clickUserInformation])

  useEffect(() => {
    if (selectedVal != 0) return
    setSelectDay(new Date())
    var state = 'display'
    formatDate(new Date(), state)
  }, [selectedVal])

  useEffect(() => {
    var state = 'display'
    formatDate(selectDay, state)
  }, [selectDay])

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchMessageList(0, PAGE_LIMIT)
    fetchBulkDeliveryList()
    fetchBuildingList()
    localStorage.setItem('id', list_id)
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    fetchData(offset)
  }, [pageNumber])

  useEffect(() => {
    if (selectedDeliveryList == null || selectedDeliveryList.length == 0) return
    if (
      selectedDeliveryList.status == 'RESERVE' ||
      selectedDeliveryList.status == 'DRAFT'
    ) {
      if (selectedDeliveryList.send_date_setting == 'NOW') return
      // 予約していた場合、画面に予約時間を反映させる
      reflectReservationDetails(selectedDeliveryList.send_date)
    }
  }, [selectedDeliveryList])

  useEffect(() => {
    if (location.state && location.state['resident_name']) {
      setSearchConditions({
        ...searchConditions,
        ['resident_name']: location.state['resident_name']
      })
    }
  }, [location.state])
  return (
    <>
      {isLoading ? (
        <Loading isLoading={props.isLoading} />
      ) : (
        <Grid className={classes.Wrapper}>
          <Grid item xs={12} className={classes.paperWrapper}>
            {searchConditions && Object.keys(searchConditions).length > 0 && (
              <Paper component="ul" className={classes.chips}>
                {Object.keys(searchConditions).map(key => {
                  let label = ''
                  switch (key) {
                    case 'kyk_id': {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = '契約ID: ' + searchConditions[key]
                      }
                      break
                    }
                    case 'resident_name': {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = '入居者名: ' + searchConditions[key]
                      }
                      break
                    }
                    case 'building_name': {
                      if (searchConditions[key]) {
                        const buildings = JSON.parse(searchConditions[key])
                        return (
                          <>
                            {buildings &&
                              buildings.length > 0 &&
                              buildings.map(building => {
                                return (
                                  <CommonChip
                                    key={building.id}
                                    label={building.TTY_NAME}
                                    onDelete={handleDeleteSearchConditions(
                                      key,
                                      building.id
                                    )}
                                  />
                                )
                              })}
                          </>
                        )
                      }
                      break
                    }
                    default: {
                      break
                    }
                  }
                  return (
                    <>
                      {label !== '' && (
                        <CommonChip
                          label={label}
                          onDelete={handleDeleteSearchConditions(key)}
                        />
                      )}
                    </>
                  )
                })}
              </Paper>
            )}
          </Grid>
          <Paper className={classes.Wrapper}>
            <Grid className={classes.titleWrapper}>
              <Grid className={`${classes.title} ${classes.titleLayout}`}>
                {selectedDeliveryList.name}一括通知
              </Grid>
              <Grid
                className={`${classes.titleResultsDisplay} ${classes.titleLayout}`}
              >
                {selectedDeliveryList.send_counts}件
              </Grid>
            </Grid>
            <Grid className={`${classes.subTitle} ${classes.subTitleLayout}`}>
              <Grid>送信内容最終確認</Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid className={classes.tableWrapper} item xs={12} sm={7}>
                <Grid className={classes.tablePaginationWrapper}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={bulkDeliveryMessagesCount}
                    rowsPerPage={PAGE_LIMIT}
                    page={pageNumber - 1}
                    onChangePage={handleChangePage}
                    onChange={() => handleSelectedItem}
                  />
                </Grid>
                {!isLoadingTypeTable ? (
                  <TableContainer
                    className={`${classes.tableContainer} ${classes.border}`}
                  >
                    <Table
                      className={`${classes.table}`}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow className={classes.tableRowTypeBlue}>
                          {columns.map((column, index) => (
                            <TableCell
                              key={index}
                              style={{ width: column.width }}
                              className={classes.tableWidhtInteraval}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBodyWrapper}>
                        {bulkDeliveryMessages.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              hover
                              className={
                                selectTable == index
                                  ? classes.tableRowTypeDefault
                                  : null
                              }
                              onClick={() =>
                                changeDisplayedInformation(row, index)
                              }
                            >
                              <TableCell>
                                {row.is_edited == true ? '編集済' : null}
                              </TableCell>
                              <TableCell>
                                <KeiyakuStatusLabel
                                  kykStatusDiv={row.kyk_status_div}
                                />
                              </TableCell>
                              <TableCell>
                                <Grid container direction="column">
                                  <Grid>
                                    {row.knr_keiyaku != null
                                      ? row.knr_keiyaku.KYK_ID
                                      : null}
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid container direction="column">
                                  <Grid item className={classes.name}>
                                    <span>
                                      {row.mst_kokyaku != null
                                        ? row.mst_kokyaku.KKY_NAME
                                        : null}
                                    </span>
                                  </Grid>
                                  <Grid item className={classes.nameKana}>
                                    <span>
                                      {row.mst_kokyaku != null
                                        ? row.mst_kokyaku.KKY_NAME_KANA
                                        : null}
                                    </span>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid container direction="column">
                                  <Grid item>
                                    <span>
                                      {row.mst_tateya != null
                                        ? row.mst_tateya.TTY_NAME
                                        : null}
                                    </span>
                                  </Grid>
                                  <Grid item>
                                    <span>
                                      {row.mst_heya != null
                                        ? row.mst_heya.HYA_ROOM_NO
                                        : null}
                                    </span>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Loading
                    isLoading={isLoading || isLoadingTypeTable}
                    marginTop={20}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={5}>
                <Grid
                  className={`${classes.messegeTitleWrapper} ${classes.subTitleLayout}`}
                >
                  メッセージ本文
                </Grid>
                <Grid className={`${classes.rightContent} ${classes.border}`}>
                  <Grid className={classes.messageDetailsWrapper}>
                    <TextareaAutosize
                      className={
                        buttonState
                          ? `${classes.body} ${classes.border} ${classes.positionTextareaAutosize} ${classes.editTextareaAutosize}`
                          : `${classes.body} ${classes.border} ${classes.positionTextareaAutosize} ${classes.notEditTextareaAutosize}`
                      }
                      defaultValue={displayMessege}
                      value={displayMessege}
                      onChange={handleChangeMessege}
                      disabled={buttonState ? false : true}
                    />
                  </Grid>
                  <Grid
                    className={`${classes.uploadTitle} ${classes.subTitleLayout}`}
                  >
                    書類アップロード
                  </Grid>
                  <Grid>
                    <Grid className={classes.uploadAreaWrapper}>
                      <Grid
                        className={`${classes.border} ${classes.uploadArea}`}
                      >
                        {pdf ? (
                          <Grid className={classes.uploadContentWrapper}>
                            <Pdf
                              attachment={pdf}
                              buttonState={buttonState}
                              handleDelete={handleDeletePdf}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                      {buttonState
                        ? pdf == undefined && (
                            <Grid container>
                              <Grid item xs={12}>
                                <input
                                  accept="application/pdf"
                                  className={classes.input}
                                  id="contained-button-file"
                                  type="file"
                                  onChange={handleChangePdf}
                                />
                              </Grid>
                              <label
                                htmlFor="contained-button-file"
                                className={`${classes.labelButtonPosition} ${classes.documentButtonTopPosition}`}
                              >
                                <Button
                                  fullWidth
                                  size="large"
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  startIcon={<AddIcon />}
                                >
                                  PDFを追加
                                </Button>
                              </label>
                            </Grid>
                          )
                        : null}
                    </Grid>
                    <Grid>
                      <Grid
                        className={`${classes.uploadImageTitle} ${classes.subTitleLayout}`}
                      >
                        画像アップロード
                      </Grid>
                      <Grid
                        className={`${classes.border} ${classes.uploadImageArea}`}
                      >
                        <Grid>
                          <Grid item xs={12}>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="contained-button-image"
                              type="file"
                              onChange={handleChangeImage}
                            />
                            {images &&
                              images.length > 0 &&
                              images.map((row, index) => (
                                <div
                                  className={classes.uploadContentWrapper}
                                  key={index}
                                >
                                  <Image
                                    attachment={row}
                                    handleDelete={handleDeleteImage(index)}
                                    style={{ marginBottom: 10 }}
                                    buttonState={buttonState}
                                    thumbnailWidth={100}
                                    thumbnailHeight={60}
                                  />
                                </div>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className={classes.labelImageButtonWrapper}>
                        {buttonState
                          ? (images == null || images.length < 3) && (
                              <label
                                htmlFor="contained-button-image"
                                className={`${classes.labelButtonPosition} ${classes.imageButtonTopPosition}`}
                              >
                                <Button
                                  fullWidth
                                  size="large"
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  startIcon={<AddIcon />}
                                >
                                  画像を追加
                                </Button>
                              </label>
                            )
                          : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    className={
                      buttonState
                        ? classes.saveButtonWrapper
                        : classes.editButtonWrapper
                    }
                  >
                    {buttonState ? (
                      <Button
                        variant="contained"
                        className={`${classes.usageSituationButtonTypeBlue} ${classes.button}`}
                        onClick={() => updateBulkDeliveryMessage()}
                      >
                        保存
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className={`${classes.usageSituationButtonTypeGray} ${classes.button}`}
                        onClick={() => changeButtonState()}
                        disabled={!clickUserInformation}
                      >
                        編集
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.setDisplayWrapper}>
              <Grid className={`${classes.chatStatusArea} ${classes.border}`}>
                <Grid
                  className={`${classes.subTitleLayout} ${classes.setDisplayTitle}`}
                >
                  チャットステータス設定
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={10}>
                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ border: 'none' }}>
                              チャットカテゴリの設定
                            </TableCell>
                            <TableCell style={{ border: 'none' }}>
                              <Grid container direction="column">
                                <Grid
                                  item
                                  className={classes.chatStatusContentTitle}
                                >
                                  大カテゴリ
                                </Grid>
                                <Grid
                                  item
                                  className={classes.chatStatusContent}
                                >
                                  {selectedDeliveryList.large_category != null
                                    ? selectedDeliveryList.large_category.name
                                    : null}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell style={{ border: 'none' }}>
                              <Grid container direction="column">
                                <Grid
                                  item
                                  className={classes.chatStatusContentTitle}
                                >
                                  中カテゴリ
                                </Grid>
                                <Grid
                                  item
                                  className={classes.chatStatusContent}
                                >
                                  {selectedDeliveryList.medium_category != null
                                    ? selectedDeliveryList.medium_category.name
                                    : null}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: 'none' }}>
                              チャット担当者の設定
                            </TableCell>
                            <TableCell style={{ border: 'none' }}>
                              <Grid container direction="column">
                                <Grid
                                  item
                                  className={classes.chatStatusContentTitle}
                                >
                                  担当グループ
                                </Grid>
                                <Grid
                                  item
                                  className={classes.chatStatusContent}
                                >
                                  {selectedDeliveryList.assigned_user_group &&
                                    getFormattedNameByStatus(
                                      selectedDeliveryList.assigned_user_group
                                        .name,
                                      selectedDeliveryList.assigned_user_group
                                        .status
                                    )}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell style={{ border: 'none' }}>
                              <Grid container direction="column">
                                <Grid
                                  item
                                  className={classes.chatStatusContentTitle}
                                >
                                  担当者
                                </Grid>
                                <Grid
                                  item
                                  className={classes.chatStatusContent}
                                >
                                  {selectedDeliveryList.assigned_user &&
                                    getFormattedNameByStatus(
                                      selectedDeliveryList.assigned_user.name,
                                      selectedDeliveryList.assigned_user.status
                                    )}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: 'none' }}>
                              チャット送信者設定
                            </TableCell>
                            <TableCell style={{ border: 'none' }}>
                              <Grid container direction="column">
                                <Grid
                                  item
                                  className={classes.chatStatusContentTitle}
                                >
                                  送信者名
                                </Grid>
                                <Grid
                                  item
                                  className={classes.chatStatusContent}
                                >
                                  {selectedDeliveryList.send_user &&
                                    getFormattedNameByStatus(
                                      selectedDeliveryList.send_user
                                        .display_name,
                                      selectedDeliveryList.send_user.status
                                    )}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: 'none' }}>
                              チャット回答ボタンの送信
                            </TableCell>
                            <TableCell
                              style={{ border: 'none' }}
                              className={classes.chatStatusContent}
                            >
                              {responseDetermination(
                                selectedDeliveryList.answer_button_type
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: 'none' }}>
                              送信後のステータス
                            </TableCell>
                            <TableCell
                              style={{ border: 'none' }}
                              className={classes.chatStatusContent}
                            >
                              {responseDetermination(
                                selectedDeliveryList.sent_status
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.setDisplayWrapper}>
              <Grid
                className={`${classes.transmissionDateSetArea} ${classes.border}`}
              >
                <Grid className={classes.subTitleLayout}>送信日時設定</Grid>
                <Grid item xs={4} className={classes.selectWrapper}>
                  <FormControl required fullWidth>
                    <Dropdown
                      name="data_link_type_id"
                      label="送信タイミング"
                      defaultValue={transmissionStatus}
                      items={dataLinkTypeList}
                      onChange={handleSelectedItem}
                    />
                  </FormControl>
                  {selectedVal == 1 ? (
                    <>
                      <Grid className={classes.transmissionDateSetAreaContent}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeJa}
                        >
                          <KeyboardDatePicker
                            fullWidth
                            disableToolbar
                            inputVariant="filled"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="application.cOC2UseParking.created_at_from"
                            name="application.cOC2UseParking.created_at_from"
                            label="送信日"
                            value={selectDay}
                            onChange={handleChangeCreatedAtDate}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            error={errorDateState}
                            helperText={errorMessageDate}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid className={classes.selectPosition}>
                        <Grid
                          className={
                            errorTimeState == true
                              ? `${classes.selectTitle} ${classes.errorSelectTitle}`
                              : `${classes.selectTitle}`
                          }
                        >
                          送信時間
                        </Grid>
                        <Grid className={classes.selectBackGround}>
                          <select
                            className={`${classes.selectWidthLeft} ${classes.selectLayout}`}
                            onChange={handleTimeChangeHH}
                          >
                            {selectTimeHh.map((row, index) =>
                              formatDay.substring(11, 13) == row ? (
                                <option key={index} selected>
                                  {row}
                                </option>
                              ) : (
                                <option key={index}>{row}</option>
                              )
                            )}
                          </select>
                          <span className={classes.comma}>:</span>
                          <select
                            className={`${classes.selectWidthRight} ${classes.selectLayout}`}
                            onChange={handleTimeChangeMM}
                          >
                            {selectTimeMm.map((row, index) =>
                              formatMM(
                                formatDay.substring(14, 16),
                                'display'
                              ) == row ? (
                                <option key={index} selected>
                                  {row}
                                </option>
                              ) : (
                                <option key={index}>{row}</option>
                              )
                            )}
                          </select>
                          {setErrorMessageTime != null ? (
                            <Grid className={classes.errorMessage}>
                              {errorMessageTime}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid item xs={4} className={classes.formatdayWrapper}>
                  {formatDay}
                </Grid>
              </Grid>
            </Grid>
            {selectedDeliveryList.status != 'CREATING' ? (
              <Grid className={classes.deleteButton}>
                <Button
                  className={classes.tertiaryButton}
                  startIcon={<DeleteIcon />}
                  onClick={() => displayConfirm('reservationCancellation')}
                  size={'large'}
                >
                  削除
                </Button>
              </Grid>
            ) : null}
            <Grid className={classes.buttonWrapper}>
              <Grid className={classes.ReturnButtonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  className={`${classes.usageSituationReturnButtonTypeGray} ${classes.button}`}
                  onClick={() => displayConfirm('return')}
                >
                  戻る
                </Button>
              </Grid>
              {selectedDeliveryList.status != 'RESERVE' ? (
                <Grid className={classes.CsvButtonWrapper}>
                  <Button
                    variant="contained"
                    className={`${classes.usageSituationButtonTypeBarkBlue} ${classes.button}`}
                    onClick={() => displayConfirm('saveDraft')}
                  >
                    下書き保存
                  </Button>
                </Grid>
              ) : null}
              <Grid className={classes.NextButtonWrapper}>
                <Button
                  variant="contained"
                  className={`${classes.usageSituationButtonTypeBlue} ${classes.button}`}
                  onClick={() => displayConfirm('send')}
                >
                  配信
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <SearchFormModalDialog
            content={
              <SearchFormContent
                buildingList={buildingList}
                searchConditions={searchConditions}
              />
            }
            footer={<SearchModalDialogFooter />}
            postData={searchPostData}
          />
        </Grid>
      )}
    </>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ConfirmBulkDelivery))
