import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './RichTextEditorStyles.css'
import { useFormContext } from 'react-hook-form'
import { ErrorMessages } from 'utils/errorMessages'

const useStyles = makeStyles(() => ({
  qlEditor: {
    textAlign: 'right'
  },
  qlToolbar: {
    textAlign: 'center'
  },
  textInput: {
    height: 200,
    '& .ql-snow .ql-tooltip': {
      position: 'absolute',
      transform: 'translate(30%,5px)'
    }
  },
  errorMessage: {
    color: 'red',
    fontSize: 12,
    textAlign: 'left',
    margin: '0 14px 4px'
  }
}))

const ToolbarContainer = props => {
  return (
    <div id={`toolbar-container-${props.id}`}>
      <span className="ql-formats">
        <select className="ql-size" defaultValue="14px">
          <option value="10px">小さめ</option>
          <option value="14px">標準</option>
          <option value="18px">大きめ</option>
        </select>
      </span>
      <span className="ql-formats">
        <select className="ql-color">
          <option value="black" />
          <option value="silver" />
          <option value="gray" />
          <option value="maroon" />
          <option value="red" />
          <option value="purple" />
          <option value="fuchsia" />
          <option value="green" />
          <option value="lime" />
          <option value="olive" />
          <option value="yellow" />
          <option value="teal" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-underline"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-link"></button>
      </span>
    </div>
  )
}

const RichTextEditor = props => {
  const classes = useStyles()
  const {
    id,
    maxLength,
    placeholder,
    defaultValue,
    error,
    setCheckError,
    checkError,
    readOnly = false
  } = props
  const { setValue } = useFormContext()

  var Size = Quill.import('attributors/style/size')
  Size.whitelist = ['10px', '14px', '18px']
  Quill.register(Size, true)

  const modules = { toolbar: `#toolbar-container-${id}` }

  const formats = ['size', 'color', 'bold', 'underline', 'link']

  const onChange = (content, delta, source, editor) => {
    // Quillの空の状態は<p><br></p>となるので、空の場合は空文字をセットする
    if (content === '<p><br></p>') {
      setValue(id, '')
      setCheckError({ ...checkError, [id]: '' })
    } else {
      setValue(id, content)
      // useFormでの文字数チェックはタグも含めて文字数をチェックしてしまう為、ここでタグを除いた文字数をチェックする
      const text = editor.getText()
      if (text.length > maxLength) {
        setCheckError({ ...checkError, [id]: ErrorMessages.BodyExcessInput })
      } else {
        setCheckError({ ...checkError, [id]: '' })
      }
    }
  }

  return (
    <div className={classes.qlEditor}>
      {error && <div className={classes.errorMessage}>{error}</div>}
      {checkError && checkError[id] && (
        <div className={classes.errorMessage}>{checkError[id]}</div>
      )}
      <ToolbarContainer id={id} />
      <ReactQuill
        className={classes.textInput}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        preserveWhitespace
        value={defaultValue}
        readOnly={readOnly}
      />
    </div>
  )
}

export default RichTextEditor
