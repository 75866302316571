import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { handleChange } from 'actions/Actions'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import FormLayout from 'components/common/FormLayout'
import RowWithFile from 'components/common/RowWithFile'

import { Storage } from 'aws-amplify'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const MAXIMUM_FILE_LENGTH = 3

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  labelButton: {
    width: '100%',
    marginTop: 10
  }
}))

function ImageForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const [images, setImages] = useState([])
  const [params, setParams] = useState([])
  const [isLoading2, setIsLoading2] = useState(false)

  const { isLoading, faqAttachment } = props

  const handleChange = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const object = {
        status: 1,
        attachment_type_id: 1,
        mime_type: file.type,
        filename: file.name,
        body: reader.result
      }

      let copy1 = []
      Object.assign(copy1, images)
      copy1.push(object)
      let copy2 = []
      Object.assign(copy2, params)
      copy2.push(file)

      setImages([...copy1])
      setParams([...copy2])
      const splicedParams = [
        ...copy2.filter(
          item =>
            item instanceof File ||
            (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
        )
      ]
      props.handleChange({ images: [...splicedParams] })
    }
    reader.readAsDataURL(file)
  }

  const handleDelete = index => {
    let copy1 = []
    Object.assign(copy1, images)
    let target1 = copy1[index]
    target1.status = 3
    copy1.splice(index, 1, target1)
    let copy2 = []
    Object.assign(copy2, params)
    let target2 = copy2[index]
    if (target2 instanceof File) {
      copy2.splice(index, 1, {})
    } else {
      target2.status = 3
      copy2.splice(index, 1, target2)
    }

    setImages([...copy1])
    setParams([...copy2])
    const splicedParams = [
      ...copy2.filter(
        item =>
          item instanceof File ||
          (Object.keys(item).indexOf('status') !== -1 && item.status == 3)
      )
    ]
    props.handleChange({ images: [...splicedParams] })
  }

  function Image({ index, status, filename, body, disabledIcon }) {
    if (status == 1) {
      return (
        <RowWithFile
          key={index}
          title={filename}
          image={body}
          handleDelete={e => handleDelete(index)}
          style={{ marginBottom: 10 }}
          disabledIcon={disabledIcon}
        />
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    if (
      faqAttachment &&
      'img_attachment' in faqAttachment &&
      faqAttachment.img_attachment
    ) {
      fetchImages(faqAttachment.img_attachment)
    }
  }, [faqAttachment, setImages, setParams])

  async function fetchImages(imageDatas) {
    if (imageDatas.length == 0) {
      setImages([])
      setParams([])
      return
    }
    const data = []
    for (let imageData of imageDatas) {
      data.push(await getImage(imageData))
    }
    setImages(data)
    setParams(data)
  }

  async function getImage(image) {
    const key = image.key
    console.log(key)
    const result = await getStorage(key, image.company_id)
    console.log(result)
    const object = Object.assign({}, image, { body: result })
    return object
  }

  async function getStorage(key, companyId) {
    return Storage.get(key, {
      level: 'protected',
      identityId: companyId,
      expires: 60
    })
  }

  return (
    <FormLayout title="添付画像">
      {isLoading || isLoading2 ? (
        <Loading isLoading={isLoading || isLoading2} />
      ) : (
        <>
          {images.length > 0 &&
            images.map(({ status, filename, body }, index) => (
              <Image
                key={index}
                index={index}
                status={status}
                filename={filename}
                body={body}
                disabledIcon={isRestrictedRole}
              />
            ))}
          {images.filter(item => item.status == 1).length <
            MAXIMUM_FILE_LENGTH && (
            <Grid container>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={handleChange}
                  disabled={isRestrictedRole}
                />
              </Grid>
              <label
                htmlFor="contained-button-file"
                className={classes.labelButton}
              >
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={isRestrictedRole}
                >
                  画像を追加
                </Button>
              </label>
            </Grid>
          )}
        </>
      )}
    </FormLayout>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    handleChange: parameter => {
      dispatch(handleChange(parameter))
    }
  }
}

export default connect(null, mapDispatchToProps)(ImageForm)
