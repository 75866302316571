import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { closeModalDialog, handleClear, setLoadData } from 'actions/Actions'
import Left from './Left'
import Right from './Right'
import Footer from './Footer'
import { withSnackbar } from 'notistack'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'
import FormModalDialog from './FormModalDialog'
import FormFooter from './FormFooter'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flex: 1,
    display: 'flex'
  },
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftBottom: {
    backgroundColor: '#fff'
  },
  right: {
    width: '462px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
}))

function EditScreen(props) {
  const classes = useStyles()

  const [bookmarkList, setBookmarkList] = useState([])
  const [bookmark, setBookmark] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [setBookmarkList])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([fetchBookmarkList()])
    setIsLoading(false)
  }

  async function fetchBookmarkList() {
    const result = await QueryHelper('getBookmarkList')
    if (!result.error) {
      setBookmarkList(result.data)
    } else {
      props.enqueueSnackbar(`しおり情報一覧を取得できませんでした。`)
    }
  }

  const handleCancel = async event => {
    event.preventDefault()
    props.handleClear()
    setBookmark(Object.assign({}, bookmark))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (Object.keys(props.parameters).length > 0) {
      props.setLoadData(true)
      const params = Object.assign({}, props.parameters)
      if (params.files) {
        const creds = await Auth.currentCredentials()
        const attachments = []
        for (let file of params.files) {
          if ('id' in file) {
            attachments.push(file)
            continue
          }
          const result = await fileupload(creds, 'files', file)
          attachments.push({
            attachment_type_id: 1,
            mime_type: file.type,
            filename: file.name,
            key: result.key
          })
        }
        delete params['files']
        params['attachments'] = attachments
      }
      if (0 !== Object.keys(bookmark).length) {
        params['id'] = bookmark.bookmark.id
        await putBookmark(params)
      } else {
        await postBookmark(params)
      }
      props.setLoadData(false)
    } else {
      props.enqueueSnackbar('変更がありません。', {
        variant: 'warning'
      })
    }
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  const postBookmark = async params => {
    props.setLoadData(true)
    const result = await MutationHelper('createBookmark', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('しおり情報を登録できませんでした。', {
        variant: 'error'
      })
    } else {
      setTimeout(function() {
        fetchBookmarkList()
      }, 1)
      setBookmark({
        bookmark: {
          id: result.id
        }
      })
      props.handleClear()
      props.enqueueSnackbar('しおり情報を登録しました。', {
        variant: 'success'
      })
      props.setLoadData(false)
    }
  }

  const putBookmark = async params => {
    props.setLoadData(true)
    const result = await MutationHelper('updateBookmark', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('しおり情報を更新できませんでした。', {
        variant: 'error'
      })
    } else {
      setTimeout(function() {
        fetchBookmarkList()
      }, 1)
      setBookmark({
        bookmark: {
          id: result.id
        }
      })
      props.handleClear()
      props.enqueueSnackbar('しおり情報を更新しました。', {
        variant: 'success'
      })
      props.setLoadData(false)
    }
  }

  async function postData(event) {
    event.preventDefault()
    props.closeModalDialog()
    props.setLoadData(true)
    if (typeof bookmark.bookmark.id == 'number') {
      const params = {
        id: bookmark.bookmark.id,
        status: 3
      }
      const result = await MutationHelper('updateBookmark', {
        input: params
      })
      if (result.error) {
        props.enqueueSnackbar('しおり情報を削除できませんでした。', {
          variant: 'error'
        })
        props.setLoadData(false)
      } else {
        fetchBookmarkList()
        setBookmark({})
        props.enqueueSnackbar('しおり情報を削除しました。', {
          variant: 'success'
        })
        props.setLoadData(false)
      }
    }
  }

  return (
    <>
      <div className={classes.container}>
        <form onSubmit={handleSubmit} className={classes.root}>
          <div className={classes.left}>
            <div className={classes.leftBody}>
              <Left isLoading={isLoading} bookmark={bookmark} />
            </div>
            <div className={classes.leftBottom}>
              <Footer bookmark={bookmark} handleCancel={handleCancel} />
            </div>
          </div>
        </form>
        <FormModalDialog
          title="しおりの削除"
          description="※削除にすると、アプリからの閲覧もできなくなります。"
          footer={<FormFooter />}
          postData={postData}
        />
        <div className={classes.right}>
          <div className={classes.rightBody}>
            <Right
              isLoading={isLoading}
              bookmarkList={bookmarkList}
              setBookmark={setBookmark}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    isOpen: state.modalDialog.isOpen
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeModalDialog: () => {
      dispatch(closeModalDialog())
    },
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditScreen))
