import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { useParams, useHistory, withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import FormLayout from 'components/common/FormLayout'
import TextInput from 'components/common/Form/TextInput'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import Dropdown from 'components/common/Form/Dropdown'
import { ChatAnswerList, BulkDeliverySentStatus } from 'utils/enum'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import Image from './Image'
import Pdf from './Pdf'
import { MutationHelper } from 'utils/api.utils'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { setLoadData } from 'actions/Actions'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  body: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '16px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W3',
    lineHeight: '24px',
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    backgroundColor: '#fff'
  },
  labelButtonUpper: {
    width: '100%',
    padding: '15px 20px',
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    display: 'flex'
  },
  subTitle: {
    marginTop: '24px'
  },
  chatStatus: {
    border: 'solid 1px rgba(158, 158, 158, 0.5)',
    marginTop: '0px',
    padding: '15px 20px'
  },
  item: {
    padding: '5px 8px 20px'
  },
  itemRow: {
    padding: '5px 8px 5px'
  },
  setButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    },
    padding: '5px 50px'
  },
  cancelButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_gray.main,
    '&:hover': {
      backgroundColor: theme.palette.c_gray.dark
    },
    padding: '5px 30px'
  },
  deleteButton: {
    color: theme.palette.c_thirdly.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      color: theme.palette.c_thirdly.contrastText,
      backgroundColor: theme.palette.c_purple.dark
    },
    padding: '6px 36px'
  },

  formControl: {
    width: '100%'
  },

  input: {
    display: 'none'
  },

  attachments: {
    border: 'solid 1px rgba(158, 158, 158, 0.5)'
  },

  helperText: {
    marginLeft: '14px'
  },

  iconButton: {
    fontSize: '1.0rem',
    padding: '0px 12px 0px 12px',
    marginTop: '6px'
  }
}))

function EditContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()
  const [isLoading, setIsLoading] = useState(null)
  const [bulkDeliveryTemplates, setBulkDeliveryTemplates] = useState(null)
  const [title, setTitle] = useState('')
  const [validateTitle, setValidateTitle] = useState({})
  const [body, setBody] = useState('')
  const [validateBody, setValidateBody] = useState({})
  const [images, setImages] = useState([])
  const [deletedImages, setDeletedImages] = useState([])
  const [pdf, setPdf] = useState(null)
  const [deletedPdf, setDeletedPdf] = useState(null)
  const [selectedParentCategory, setSelectedParentCategory] = useState(null)
  const [
    validateSelectedParentCategory,
    setValidateSelectedParentCategory
  ] = useState(null)
  const [selectedChildCategory, setSelectedChildCategory] = useState(null)
  const [
    validateSelectedChildCategory,
    setValidateSelectedChildCategory
  ] = useState(null)
  const [selectChildCategoryList, setSelectChildCategoryList] = useState(null)
  const [parentCategoryList, setParentCategoryList] = useState(null)
  const [childCategoryList, setChildCategoryList] = useState(null)
  const [selectedChatAnswer, setSelectedChatAnswer] = useState(
    ChatAnswerList.nothing.value
  )
  const [selectedSendState, setSelectedSendState] = useState(null)
  const [validateSelectedSendState, setValidateSelectedSendState] = useState(
    null
  )

  useEffect(() => {
    fetchData(id)
  }, [setBulkDeliveryTemplates])

  const fetchBulkDeliveryTemplates = async bulkDeliveryTemplatesId => {
    let result = await QueryHelper(
      'getBulkDeliveryTemplates',
      { filter: { id: { eq: bulkDeliveryTemplatesId } } },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('配信リスト一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBulkDeliveryTemplates(result)
      setTitle(result.data[0].title)
      setBody(result.data[0].body)
      const images = []
      let pdf = null
      if (result.data[0].attachments !== null) {
        for (let attachment of result.data[0].attachments) {
          if (attachment.attachment_type_id === 1) {
            images.push(attachment)
          }
          if (attachment.attachment_type_id === 3) {
            pdf = attachment
          }
        }
      }
      setImages(images)
      setPdf(pdf)
      setSelectedParentCategory(result.data[0].large_category.id)
      setSelectedChildCategory(result.data[0].medium_category.id)
      setSelectedChatAnswer(
        ChatAnswerList.fromKey(result.data[0].answer_button_type).value
      )
      setSelectedSendState(
        BulkDeliverySentStatus.fromKey(result.data[0].sent_status).value
      )
    }
  }

  const fetchData = async bulkDeliveryTemplatesId => {
    setIsLoading(true)
    await fetchCategoryList()
    await Promise.all([
      bulkDeliveryTemplatesId
        ? fetchBulkDeliveryTemplates(bulkDeliveryTemplatesId)
        : ''
    ])
    setIsLoading(false)
  }

  const fetchCategoryList = async () => {
    const result = await QueryHelper('getLargeCategoryAndMediumCategory')
    if (!result.error) {
      const parents = [{ value: 0, label: '' }]
      const children = { 0: [] }
      for (let row1 of result) {
        parents.push({
          value: row1.large_categories.id,
          label: row1.large_categories.name
        })
        const elements = [{ value: 0, label: '' }]
        for (let row2 of row1.medium_categories) {
          elements.push({
            value: row2.id,
            label: row2.name
          })
        }
        children[row1.large_categories.id] = elements
      }
      setParentCategoryList(parents)
      setChildCategoryList(children)
    } else {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    }
  }

  const handleChangeTitle = event => {
    setTitle(event.target.value)
  }

  const handleChangeBody = event => {
    setBody(event.target.value)
  }

  const handleChangeImage = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mine_type = file.type
      file.filename = file.name
      file.body = reader.result
      setImages([...images, file])
    }
    reader.readAsDataURL(file)
  }

  const handleDeleteImage = index => event => {
    const splicedImages = [...images.filter((item, i) => i !== index)]
    document.getElementById('contained-button-image').value = null
    if (images && images.length > 0 && images[index].id) {
      const temp = [...deletedImages]
      temp.push(images[index])
      setDeletedImages([...temp])
    }
    setImages([...splicedImages])
  }

  const handleChangePdf = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 3
      file.mine_type = file.type
      file.filename = file.name
      file.body = reader.result
      setPdf(file)
    }
    reader.readAsDataURL(file)
  }

  const handleDeletePdf = event => {
    document.getElementById('contained-button-pdf').value = null
    if (pdf && pdf.id) {
      setDeletedPdf(pdf)
    }
    setPdf(null)
  }

  const handleChangeParentCategory = (event, value) => {
    if (value == 0) {
      setSelectChildCategoryList([])
    } else {
      setSelectChildCategoryList(childCategoryList[event.target.value])
    }
    setSelectedParentCategory(event.target.value)
    setSelectedChildCategory(0)
  }

  const handleChangeChildCategory = event => {
    setSelectedChildCategory(event.target.value)
  }

  const handleChangeChatAnswer = event => {
    setSelectedChatAnswer(event.target.value)
  }

  const handleChangeSendState = event => {
    setSelectedSendState(event.target.value)
  }

  const handleCancel = event => {
    event.preventDefault()
    if (!window.confirm(`編集内容を破棄します。よろしいですか？`)) {
      return
    }
    history.push(`/setting/bulk-delivery/template/list`)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.setLoadData(true)
    if (validateForm()) {
      props.setLoadData(false)
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    const params = {}

    if (title) {
      params.title = title
    }
    if (body) {
      params.body = body
    }
    if (selectedParentCategory) {
      if (selectedChildCategory) {
        params.medium_category_id = selectedChildCategory
      }
    }
    if (selectedChatAnswer) {
      params.answer_button_type = ChatAnswerList.fromValue(
        selectedChatAnswer
      ).key
    }
    if (selectedSendState) {
      params.sent_status = BulkDeliverySentStatus.fromValue(
        selectedSendState
      ).key
    }
    if ((images && images.length > 0) || pdf) {
      params.attachments = []
      const creds = await Auth.currentCredentials()
      for (let image of images) {
        if (!image.id) {
          const result = await fileupload(creds, 'images', image)
          params.attachments.push({
            attachment_type_id: 1,
            mime_type: image.type,
            filename: image.name,
            key: result.key
          })
        }
      }

      if (pdf && !pdf.id) {
        const result = await fileupload(creds, 'pdfs', pdf)
        params.attachments.push({
          attachment_type_id: 3,
          mime_type: pdf.type,
          filename: pdf.name,
          key: result.key
        })
      }
    }

    if ((deletedImages && deletedImages.length > 0) || deletedPdf) {
      if (!params.attachments) {
        params.attachments = []
      }
      if (deletedImages && deletedImages.length > 0) {
        if (!params.attachments) {
          params.attachments = []
        }
        for (let image of deletedImages) {
          if (image.id) {
            params.attachments.push({
              id: image.id,
              key: image.key,
              status: 3
            })
          }
        }
      }

      if (deletedPdf) {
        if (!params.attachments) {
          params.attachments = []
        }
        params.attachments.push({
          id: deletedPdf.id,
          key: deletedPdf.key,
          status: 3
        })
      }
    }

    let result
    if (id) {
      params.id = parseInt(id)
      result = await MutationHelper('updateBulkDeliveryTemplate', {
        input: params
      })
      if (result.error) {
        console.log(JSON.stringify(result))
        props.enqueueSnackbar('保存できませんでした', {
          variant: 'error'
        })
      } else {
        await fetchBulkDeliveryTemplates(result.id)
        props.enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        history.push(`/setting/bulk-delivery/template/list`)
      }
    } else {
      result = await MutationHelper('createBulkDeliveryTemplate', {
        input: params
      })
      if (result.error) {
        console.log(JSON.stringify(result))
        props.enqueueSnackbar('保存できませんでした', {
          variant: 'error'
        })
      } else {
        props.enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        history.push(`/setting/bulk-delivery/template/list`)
      }
    }
    props.setLoadData(false)
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    console.log(JSON.stringify(result))
    return result
  }

  const handleDeleteBulkDeliveryTemplate = async event => {
    event.preventDefault()
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }
    props.setLoadData(true)
    const params = {}
    params.id = id
    const result = await MutationHelper('deleteBulkDeliveryTemplate', {
      input: params
    })
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('削除できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('削除しました', {
        variant: 'success'
      })
      history.push(`/setting/bulk-delivery/template/list`)
    }
    props.setLoadData(false)
  }

  function validateForm() {
    let isError = false

    if (!title || title === '') {
      setValidateTitle({ hasError: true, message: '必須です' })
      isError = true
    } else if (title.length > 30) {
      setValidateTitle({ hasError: true, message: '30文字を超えています' })
      isError = true
    } else {
      setValidateTitle({})
    }

    if (!body || body === '') {
      setValidateBody({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateBody({})
    }

    if (!selectedParentCategory) {
      setValidateSelectedParentCategory({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedParentCategory({})
    }

    if (!selectedChildCategory) {
      setValidateSelectedChildCategory({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateSelectedChildCategory({})
    }

    if (!selectedSendState) {
      setValidateSelectedSendState({ hasError: true, message: '必須です' })
      isError = true
    } else if (
      selectedChatAnswer != ChatAnswerList.nothing.value &&
      selectedSendState == BulkDeliverySentStatus.complete.value
    ) {
      // 回答ボタンありの場合は、ステータス完了を選択できない
      setValidateSelectedSendState({
        hasError: true,
        message: 'チャット回答ボタンを送る場合、完了は選択できません'
      })
      isError = true
    } else {
      setValidateSelectedSendState({})
    }
    return isError
  }

  return (
    <>
      {props.isLoading ? (
        <Loading isLoading={props.isLoading} />
      ) : (
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormLayout title="送信メッセージ設定">
            <Typography variant="subtitle1">テンプレートタイトル</Typography>
            <Grid xs={6}>
              <TextInput
                required
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={title}
                defaultValue={title}
                onChange={handleChangeTitle}
                error={validateTitle.hasError}
                helperText={
                  validateTitle.message ? validateTitle.message : '30文字以内'
                }
                disabled={!isUnrestrictedRole}
              />
            </Grid>
            <Grid>
              <Typography variant="subtitle1" className={classes.subTitle}>
                送信メッセージ本文
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                className={classes.body}
                rowsMin="16"
                value={body}
                defaultValue={body}
                onChange={handleChangeBody}
                disabled={!isUnrestrictedRole}
              />
              <FormHelperText
                className={classes.helperText}
                error={validateBody && validateBody.hasError}
              >
                {validateBody && validateBody.message
                  ? validateBody.message
                  : ''}
              </FormHelperText>
            </Grid>
            <Typography variant="subtitle1" className={classes.subTitle}>
              書類アップロード(画像/PDF)
            </Typography>
            <Grid container spacing={2}>
              <Grid alignItems="center" item xs={6}>
                <Grid className={classes.attachments}>
                  <FormLayout
                    title="添付画像"
                    subTitle={'※画像は3つまで添付可'}
                  >
                    <Grid item xs={12}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-image"
                        type="file"
                        onChange={handleChangeImage}
                        disabled={!isUnrestrictedRole}
                      />
                    </Grid>
                    {images &&
                      images.length > 0 &&
                      images.map((row, index) => (
                        <div key={index} style={{ marginBottom: 10 }}>
                          <Image
                            attachment={row}
                            handleDelete={handleDeleteImage(index)}
                            style={{ marginBottom: 10 }}
                            disabledIcon={!isUnrestrictedRole}
                          />
                        </div>
                      ))}
                    {(images == null || images.length < 3) && (
                      <label htmlFor="contained-button-image">
                        <Button
                          fullWidth
                          size="large"
                          variant="outlined"
                          color="primary"
                          component="span"
                          startIcon={<AddIcon />}
                          disabled={!isUnrestrictedRole}
                        >
                          画像を追加
                        </Button>
                      </label>
                    )}
                  </FormLayout>
                </Grid>
              </Grid>
              <Grid alignItems="center" item xs={6}>
                <Grid className={classes.attachments}>
                  <FormLayout title="添付PDF" subTitle={'※PDFは1つまで添付可'}>
                    <Grid item xs={12}>
                      <input
                        accept="application/pdf"
                        className={classes.input}
                        id="contained-button-pdf"
                        type="file"
                        onChange={handleChangePdf}
                        disabled={!isUnrestrictedRole}
                      />
                    </Grid>
                    {pdf ? (
                      <Pdf
                        attachment={pdf}
                        handleDelete={handleDeletePdf}
                        disabledIcon={!isUnrestrictedRole}
                      />
                    ) : (
                      <label htmlFor="contained-button-pdf">
                        <Button
                          fullWidth
                          size="large"
                          variant="outlined"
                          color="primary"
                          component="span"
                          startIcon={<AddIcon />}
                          disabled={!isUnrestrictedRole}
                        >
                          PDFを追加
                        </Button>
                      </label>
                    )}
                  </FormLayout>
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" className={classes.subTitle}>
              チャットステータス設定
            </Typography>
            <Grid container className={classes.chatStatus}>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2">チャットカテゴリの設定</Typography>
                <Typography
                  style={{
                    fontFamily: 'Hiragino Sans W1',
                    fontSize: '12px'
                  }}
                >
                  送信するチャットのカテゴリを設定します（必須）
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Dropdown
                    required
                    name="大カテゴリー"
                    label={'大カテゴリ'}
                    onChange={handleChangeParentCategory}
                    defaultValue={selectedParentCategory}
                    items={parentCategoryList}
                    disabled={!isUnrestrictedRole}
                  />
                </FormControl>
                <FormHelperText
                  className={classes.helperText}
                  error={
                    validateSelectedParentCategory &&
                    validateSelectedParentCategory.hasError
                  }
                >
                  {validateSelectedParentCategory &&
                  validateSelectedParentCategory.message
                    ? validateSelectedParentCategory.message
                    : ''}
                </FormHelperText>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Dropdown
                    required
                    name="中カテゴリー"
                    label={'中カテゴリ'}
                    onChange={handleChangeChildCategory}
                    defaultValue={selectedChildCategory}
                    items={
                      selectedParentCategory != null
                        ? childCategoryList[selectedParentCategory]
                        : [
                            {
                              value: '',
                              label: '大カテゴリを選択してください'
                            }
                          ]
                    }
                    disabled={!isUnrestrictedRole}
                  />
                </FormControl>
                <FormHelperText
                  className={classes.helperText}
                  error={
                    validateSelectedChildCategory &&
                    validateSelectedChildCategory.hasError
                  }
                >
                  {validateSelectedChildCategory &&
                  validateSelectedChildCategory.message
                    ? validateSelectedChildCategory.message
                    : ''}
                </FormHelperText>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <Typography variant="body2">
                  チャット回答ボタンの送信
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Hiragino Sans W1',
                    fontSize: '12px'
                  }}
                >
                  メッセージと一緒に回答ボタンを送ります。入居者がボタンを押すと自動的に完了になります。
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.item}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Dropdown
                    name="チャット回答ボタン"
                    label={'チャット回答ボタン'}
                    onChange={handleChangeChatAnswer}
                    defaultValue={selectedChatAnswer}
                    items={ChatAnswerList.values()}
                    disabled={!isUnrestrictedRole}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.item}></Grid>
              <Grid item xs={4} className={classes.itemRow}>
                <Typography variant="body2">送信後のステータス</Typography>
                <Typography
                  style={{
                    fontFamily: 'Hiragino Sans W1',
                    fontSize: '12px'
                  }}
                  className={classes.item}
                >
                  送信後のステータスを設定します（必須）
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.itemRow}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Dropdown
                    required
                    name="送信ステータス"
                    label={'ステータス'}
                    onChange={handleChangeSendState}
                    defaultValue={selectedSendState}
                    items={BulkDeliverySentStatus.values()}
                    disabled={!isUnrestrictedRole}
                  />
                </FormControl>
                <FormHelperText
                  className={classes.helperText}
                  error={
                    validateSelectedSendState &&
                    validateSelectedSendState.hasError
                  }
                >
                  {validateSelectedSendState &&
                  validateSelectedSendState.message
                    ? validateSelectedSendState.message
                    : ''}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container className={classes.subTitle}>
              <div className={classes.labelButtonUpper}>
                <Grid container justify="space-between">
                  <Grid item>
                    {id && (
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleDeleteBulkDeliveryTemplate}
                        disabled={!isUnrestrictedRole}
                      >
                        <DeleteIcon />
                        削除
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          className={classes.cancelButton}
                          variant="contained"
                          onClick={handleCancel}
                          disabled={!isUnrestrictedRole}
                        >
                          キャンセル
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          className={classes.setButton}
                          variant="contained"
                          type="submit"
                          disabled={!isUnrestrictedRole}
                        >
                          保存
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </FormLayout>
        </form>
      )}
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(EditContent)))
