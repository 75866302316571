import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { PackageEnabled } from 'utils/enum'
import Grid from '@material-ui/core/Grid'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import Label from 'components/common/Label'
import CheckIcon from '@material-ui/icons/Check'
import { handleOpenPage } from 'actions/Actions'
import { useIsTextSelected } from 'hooks/useIsTextSelected'

const useStyles = makeStyles(theme => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const columns = [
  { id: 'status', label: '状態', minWidth: 60, width: 60 },
  { id: 'name', label: 'サービス名', minWidth: 150, width: 150 },
  { id: 'body', label: '説明文', minWidth: 200, width: 200 },
  {
    id: 'is_display_top',
    label: (
      <>
        TOP
        <br />
        表示
      </>
    ),
    minWidth: 20,
    width: 20
  }
]

function CellWithStatus({ status }) {
  switch (status) {
    case PackageEnabled.enabled.code:
      return <Label color="blue" text={PackageEnabled.enabled.value} />
    case PackageEnabled.disabled.code:
      return <Label color="gray" text={PackageEnabled.disabled.value} />
    default:
      return <Label color="gray" text={'不明'} />
  }
}

function CellWithBody({ body }) {
  if (body.length > 108) {
    return body.substr(0, 108)
  }
  return body
}

const CellWithDisplayTop = ({ status, isTopDisplay }) => {
  return <>{status === 1 && isTopDisplay && <CheckIcon />}</>
}

function List(props) {
  const classes = useStyles()
  const history = useHistory()
  const isTextSelected = useIsTextSelected()

  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  const navigateToDetailPage = (e, id) => {
    if (isTextSelected()) return

    handleOpenPage(
      `/management/package/edit/${id}`,
      e,
      history,
      props.conditions
    )
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  width: column.width
                }}
                sortDirection={
                  column.hasSort && orderBy === column.id ? order : false
                }
              >
                {column.hasSort ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    className={classes.tableSort}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <>{column.label}</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.packageList.length
            ? props.packageList.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={e => navigateToDetailPage(e, row.package.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell>
                      <CellWithStatus status={row.package.status} />
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          {row.package.name}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <CellWithBody body={row.package.body} />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <CellWithDisplayTop
                        status={row.package.status}
                        isTopDisplay={row.package.is_display_top}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    </>
  )
}

export default withRouter(List)
